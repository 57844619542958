import React from "react";
import { useLocalStorage } from "./useLocalStorage";
import PlayerInputForm from "./PlayerInputForm";
import PlayerRatingForm from "./PlayerRatingForm";
import { Button, Box } from "@mui/material";
import TopbarTwo from "../../scene/global/TopBarTwo";

function SingleGame() {
  const [players, setPlayers] = useLocalStorage("storedPlayers", []);
  const [showRatings, setShowRatings] = useLocalStorage(
    "storedShowRatings",
    false
  );
  const [counters, setCounters] = useLocalStorage(
    "storedCounters",
    Array(players.length).fill(0)
  );
  const [previousCounters, setPreviousCounters] = useLocalStorage(
    "storedPreviousCounters",
    Array(players.length).fill(0)
  );

  const [maxPoints, setMaxPoints] = useLocalStorage("storedMaxPoints", 50);
  const [lockedSliders, setLockedSliders] = useLocalStorage(
    "storedLockedSliders",
    false
  );
  const [sliderHistory, setSliderHistory] = useLocalStorage(
    "storedSliderHistory",
    []
  );

  const handleSubmit = (playerList, pointSelection) => {
    setPlayers(playerList);
    setCounters(Array(playerList.length).fill(0));
    setShowRatings(true);
    setMaxPoints(pointSelection);
    setLockedSliders(false);
  };

  const handleLockToggle = () => {
    setLockedSliders(!lockedSliders);
  };

  const handleSliderChange = (index, newValue) => {
    setSliderHistory((prevSliderHistory) => {
      const newHistory = [...prevSliderHistory];
      newHistory.push(counters);
      return newHistory;
    });

    setCounters((prevCounters) => {
      const updatedCounters = [...prevCounters];
      updatedCounters[index] = newValue;
      return updatedCounters;
    });
  };
  const handleUndo = () => {
    setSliderHistory((prevSliderHistory) => {
      if (prevSliderHistory.length > 0) {
        setCounters(prevSliderHistory[prevSliderHistory.length - 1]);
        const newHistory = prevSliderHistory.slice(0, -1);
        return newHistory;
      } else {
        return prevSliderHistory;
      }
    });
  };
  // Hinzufügen der handleUndo-
  const handleReset = () => {
    setCounters(Array(players.length).fill(0));
  };

  const handleBackButtonClick = () => {
    setShowRatings(false);
  };

  return (
    <Box>
      <Box>
        <TopbarTwo />
      </Box>
      <div className="singleModeContainer">
        {!showRatings && <PlayerInputForm onSubmit={handleSubmit} />}
        {showRatings && (
          <PlayerRatingForm
            players={players}
            counters={counters}
            onSliderChange={handleSliderChange}
            onBackButtonClick={handleBackButtonClick}
            onReset={handleReset}
            maxPoints={maxPoints}
            sliderValues={counters}
            onUndo={handleUndo}
            lockedSliders={lockedSliders}
            onLockToggle={handleLockToggle}
          />
        )}
      </div>
    </Box>
  );
}
export default SingleGame;
