import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore";
import MobileDetect from "mobile-detect";
import { Box, Typography, Snackbar, IconButton } from "@mui/material";
import "../../index.css";
import CloseIcon from "@mui/icons-material/Close";
import SubHeaderTurnier from "../../components/SubHeaderTurnier";
import PlayerBarChart from "../../components/ChartComponent";
import TopbarTwo from "../../scene/global/TopBarTwo";

import {
  turnierInfoCollectionName,
  playerInfoCollectionName,
  db,
  TurnierInfoCollection,
} from "../../firebaseConfig";
import { onSnapshot } from "firebase/firestore";

const LiveTurnier = () => {
  const [currentRound, setCurrentRound] = useState(1);
  const [currentTable, setCurrentTable] = useState(-1); // "All" is a special value wHY?

  const [tableData, setTableData] = useState({});
  const [liveMode, setLiveMode] = useState("12 Spieler");
  const [isLoading, setIsLoading] = useState(true);

  const [currentTurnier, setCurrentTurnier] = useState("");
  const [liveTurniere, setLiveTurniere] = useState([]);
  const [currentTurnierIndex, setCurrentTurnierIndex] = useState(0);

  const incrementTurnierIndex = () => {
    setCurrentTurnierIndex((prevIndex) =>
      prevIndex + 1 >= liveTurniere.length ? 0 : prevIndex + 1
    );
  };

  const decrementTurnierIndex = () => {
    setCurrentTurnierIndex((prevIndex) =>
      prevIndex - 1 < 0 ? liveTurniere.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const getLiveTurnier = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const uid = user.uid;
        const q = query(TurnierInfoCollection, where("live", "==", true));
        const turnierDoc = await getDocs(q);
        if (turnierDoc.size > 0) {
          const turniere = turnierDoc.docs.map((doc) => doc.data());
          setLiveTurniere(turniere);

          setLiveMode(turniere[currentTurnierIndex].mode);
          setCurrentTurnier(turniere[currentTurnierIndex].name); // Aktualisieren Sie den currentTurnier-State
        }
      }
    };

    getLiveTurnier();
  }, [currentTurnierIndex]);

  const loadInitialData = async () => {
    setIsLoading(true);

    const turnierQuerySnapshot = await getDocs(
      query(
        collection(db, turnierInfoCollectionName),
        where("name", "==", currentTurnier)
      )
    );

    // Subscribe to real-time updates
    const unsubscribe = onSnapshot(
      query(
        collection(db, playerInfoCollectionName),
        where("turnier", "==", currentTurnier)
      ),
      (playersQuerySnapshot) => {
        const playersData = [];
        playersQuerySnapshot.forEach((doc) => {
          playersData.push({
            spielerName: doc.data().spielerName,
            punkte: doc.data().punkte,
            runde: parseInt(doc.data().runde.split(" ")[1]),
            tisch: parseInt(doc.data().tisch.split(" ")[1]),
          });
        });

        // Group players by round and table
        const tables = {};
        for (let i = 0; i < playersData.length; i++) {
          const player = playersData[i];
          const runde = player.runde;
          const tisch = player.tisch;

          if (!tables[runde]) {
            tables[runde] = {};
          }
          if (!tables[runde][tisch]) {
            tables[runde][tisch] = [];
          }
          tables[runde][tisch].push(player);
        }

        // Flatten the data structure for easier rendering
        const flattenedTables = {};
        Object.keys(tables).forEach((runde) => {
          Object.keys(tables[runde]).forEach((tisch) => {
            const key = `${runde}_${tisch}`;
            flattenedTables[key] = tables[runde][tisch];
          });
        });

        setTableData(flattenedTables);
        setIsLoading(false);
      }
    );

    return () => {
      // Unsubscribe from real-time updates when the component unmounts
      unsubscribe();
    };
  };

  useEffect(() => {
    loadInitialData();
  }, [currentRound, currentTable, liveMode, currentTurnier]);

  const maxRound = 10; // Anzahl der maximalen Tische anpassen

  const incrementRound = () => {
    if (currentRound === -1) {
      setCurrentRound(1);
    } else if (currentRound < maxRound) {
      setCurrentRound(currentRound + 1);
    } else {
      setCurrentRound(1);
    }
  };

  const decrementRound = () => {
    if (currentRound === -1) {
      setCurrentRound(maxRound);
    } else if (currentRound > 1) {
      setCurrentRound(currentRound - 1);
    } else {
      setCurrentRound(1);
    }
  };
  const maxTables = 6; // Anzahl der maximalen Tische anpassen

  const incrementTable = () => {
    if (currentTable === -1) {
      setCurrentTable(1);
    } else if (currentTable < maxTables) {
      setCurrentTable(currentTable + 1);
    } else {
      setCurrentTable(-1);
    }
  };

  const decrementTable = () => {
    if (currentTable === -1) {
      setCurrentTable(maxTables);
    } else if (currentTable > 1) {
      setCurrentTable(currentTable - 1);
    } else {
      setCurrentTable(-1);
    }
  };

  const getTableConfig = (liveMode) => {
    const mode = parseInt(liveMode);
    if (mode === 12) {
      return [3, 3, 2, 1, 1];
    } else if (mode === 16) {
      return [4, 4, 2, 2, 1, 1];
    } else if (mode === 18) {
      return [6, 3, 3, 2, 1, 1];
    } else if (mode === 20) {
      return [4, 4, 4, 2, 2, 1, 1];
    } else if (mode === 24) {
      return [6, 6, 3, 3, 2, 1, 1];
    } else {
      throw new Error("Invalid mode.");
    }
  };

  // Verwenden Sie den liveMode als Parameter für die getTableConfig-Funktion
  const tableConfig = getTableConfig(liveMode);

  const renderCharts = () => {
    if (isLoading) {
      return <Typography>Loading...</Typography>;
    }

    if (currentTable === -1) {
      return (
        <div className="chartWrapper" onClick={handleChartWrapperClick}>
          {Object.keys(tableData)
            .filter((key) => key.startsWith(`${currentRound}_`))
            .map((tableNumber) => (
              <div
                className="chartContainer"
                key={`${tableNumber}_${chartKey}`}
              >
                <PlayerBarChart
                  className="chartCanvas"
                  tableData={tableData[tableNumber]}
                  numBars={tableData[tableNumber].length}
                />
              </div>
            ))}
        </div>
      );
    } else {
      const key = `${currentRound}_${currentTable}`;
      return (
        <div
          className="chartWrapper"
          onClick={(event) => {
            handleChartWrapperClick(event);
          }}
        >
          <div className="chartContainerFull" key={`full_${chartKey}`}>
            <PlayerBarChart
              className="chartCanvas"
              tableData={tableData[key] || []}
              numBars={4}
            />
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    const md = new MobileDetect(window.navigator.userAgent);
    if (md.phone()) {
      const onOrientationChange = () => {
        if (window.orientation !== 90 && window.orientation !== -90) {
          return () => {
            Snackbar.show({ text: "Bitte drehen Sie Ihr Gerät um 90 Grad." });
          };
        }
      };

      window.addEventListener("orientationchange", onOrientationChange);
      onOrientationChange(); // Überprüfen Sie die Ausrichtung bei der Initialisierung

      return () => {
        window.removeEventListener("orientationchange", onOrientationChange);
      };
    }
  }, []);

  const [chartKey, setChartKey] = useState(0);
  const handleChartWrapperClick = (event) => {
    if (isFullscreen()) {
      closeFullscreen();
    } else {
      handleFullscreen(event.currentTarget);
    }
  };

  const handleFullscreen = (element) => {
    const fullscreenContainer = document.querySelector(".fullscreenContainer");

    const enterFullscreen = () => {
      fullscreenContainer.appendChild(element);
      element.classList.add("fullscreenBackground");
      fullscreenContainer.classList.add("active");

      if (fullscreenContainer.requestFullscreen) {
        fullscreenContainer.requestFullscreen().catch((err) => {
          console.error(
            `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
          );
        });
      } else if (fullscreenContainer.webkitRequestFullscreen) {
        // Safari auf iOS
        fullscreenContainer.webkitRequestFullscreen();
      } else {
        // Fallback für iPhone
        element.classList.add("fullscreenFallback");
      }
    };

    const exitFullscreen = () => {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        // Safari auf iOS
        document.webkitExitFullscreen();
      } else {
        // Fallback für iPhone
        element.classList.remove("fullscreenFallback");
      }

      element.classList.remove("fullscreenBackground");
      fullscreenContainer.classList.remove("active");
      document.querySelector(".rightContent").appendChild(element);
    };

    if (!isFullscreen()) {
      enterFullscreen();
    } else {
      exitFullscreen();
    }

    // Increment the chartKey to force a re-render
    setChartKey((prevKey) => prevKey + 1);
  };

  const isFullscreen = () => {
    return !!document.fullscreenElement || !!document.webkitFullscreenElement;
  };

  useEffect(() => {
    // Event listener for fullscreen change
    const onFullscreenChange = () => {
      // Re-render the charts
      renderCharts();
    };

    document.addEventListener("fullscreenchange", onFullscreenChange);
    document.addEventListener("webkitfullscreenchange", onFullscreenChange);

    // Clean up the event listeners when the component unmounts
    return () => {
      document.removeEventListener("fullscreenchange", onFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        onFullscreenChange
      );
    };
  }, []);

  const closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      // Safari auf iOS
      document.webkitExitFullscreen();
    } else {
      // Fallback für iPhone
      const wrapper = document.querySelector(".chartWrapper");
      wrapper.classList.remove("fullscreenFallback");
    }

    const wrapper = document.querySelector(".chartWrapper");
    wrapper.classList.remove("fullscreenBackground");
    document.querySelector(".fullscreenContainer").classList.remove("active");
    document.querySelector(".rightContent").appendChild(wrapper);

    // Increment the chartKey to force a re-render
    setChartKey((prevKey) => prevKey + 1);
  };

  const [openSnackbar, setOpenSnackbar] = useState(false);
  useEffect(() => {
    const md = new MobileDetect(window.navigator.userAgent);
    if (md.phone()) {
      const onOrientationChange = () => {
        if (window.orientation !== 90 && window.orientation !== -90) {
          setOpenSnackbar(true);
        } else {
          setOpenSnackbar(false);
        }
      };

      window.addEventListener("orientationchange", onOrientationChange);
      onOrientationChange();

      return () => {
        window.removeEventListener("orientationchange", onOrientationChange);
      };
    }
  }, []);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box className="rightContent">
      <Snackbar
        anchorOrigin={{
          vertical: "middle",
          horizontal: "center",
        }}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message="Bitte drehen Sie Ihr Gerät. und warten sie einen Moment bis die Daten laden."
        autoHideDuration={6000}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      <Box
        sx={{
          "@media screen and (min-width: 1200px)": {
            display: "none",
            opacity: 1,
          },
        }}
      >
        <TopbarTwo />
      </Box>
      <SubHeaderTurnier
        currentRound={currentRound}
        incrementRound={incrementRound}
        decrementRound={decrementRound}
        currentTable={currentTable}
        incrementTable={incrementTable}
        decrementTable={decrementTable}
        onGetDataButtonClick={loadInitialData}
        currentTournament={liveTurniere[currentTurnierIndex]}
        decrementTurnierIndex={decrementTurnierIndex}
        incrementTurnierIndex={incrementTurnierIndex}
        liveTurniere={liveTurniere}
      />
      {renderCharts()}

      <div className="fullscreenContainer">
        <IconButton
          className="closeFullscreen"
          onClick={closeFullscreen}
          color="inherit"
        >
          <Typography
            variant="h5"
            sx={{
              marginX: "1rem",
            }}
          >
            KAPKAN Turnier Live - Runde{" "}
            {currentRound === -1 ? "Alle" : currentRound}
          </Typography>
          <Box className="CloseIcon">
            <CloseIcon />
          </Box>
        </IconButton>
      </div>
    </Box>
  );
};

export default LiveTurnier;
