import React, { useState } from "react";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";

import LogoutButton from "../../components/logOutButton";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import ScoreboardIcon from "@mui/icons-material/Scoreboard";
import QueuePlayNextIcon from "@mui/icons-material/QueuePlayNext";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";

import GridViewIcon from "@mui/icons-material/GridView";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const Item = ({ title, to, icon, selected, setSelected, collapsed }) => {
  const isSelected = selected === title;
  return (
    <ListItem
      className="mobilSidebar"
      button
      selected={isSelected}
      onClick={() => setSelected(title)}
    >
      <ListItemIcon
        sx={{
          width: "fit-content",
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText
        className="SidebarItemText"
        primary={title}
        sx={{
          opacity: collapsed ? 0 : 1,
          transition: "opacity 0.2s",
          padding: "none",

          "@media screen and (max-width: 1100px)": {
            display: "none",
          },
        }}
      />
      <Link to={to} />
    </ListItem>
  );
};

const SidebarC = () => {
  const [selected, setSelected] = useState("Dashboard");
  const [collapsed, setCollapsed] = useState(false);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Box className="Sidebar">
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
          width: collapsed ? "50px" : "100%",
          height: "100%",
          transition: "width 0.3s",
          "@media screen and (max-width: 1100px)": {
            width: "100%",
          },
        }}
      >
        <List className="SideBarList">
          <Box
            sx={{
              display: "flex",
            }}
          >
            <IconButton
              onClick={toggleSidebar}
              sx={{
                color: (theme) => theme.palette.text.primary,
                "@media screen and (max-width: 1100px)": {
                  display: "none",
                },
                "@media screen and (max-height: 480px)": {
                  display: "none",
                },
              }}
            >
              {collapsed ? <MenuIcon /> : <ChevronLeftIcon />}
            </IconButton>
            <Link className="HeaderLink" to="/">
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  width: "100%",
                  paddingLeft: "8px",
                }}
              >
                <Box>
                  <h1
                    style={{
                      margin: "0",
                      fontSize: "1.2rem",
                      lineHeight: "1.2rem",
                      display: collapsed ? "none" : "block",
                    }}
                  >
                    KAPKAN!
                  </h1>
                  <h2
                    style={{
                      margin: "0",
                      fontSize: "0.8rem",
                      lineHeight: "0.8rem",
                      display: collapsed ? "none" : "block",
                    }}
                  >
                    Online
                  </h2>
                </Box>
              </Box>
            </Link>
          </Box>

          <Link to="/" className="LinkSide">
            <Item
              title="Start"
              icon={<GridViewIcon />}
              selected={selected}
              setSelected={setSelected}
              collapsed={collapsed}
            />
          </Link>
          <Link to="/SingleGame" className="LinkSide">
            <Item
              title="Einzelspiel"
              icon={
                <TableRestaurantIcon
                  sx={{
                    color: "rgba(170, 55, 176, 1)",
                  }}
                />
              }
              selected={selected}
              setSelected={setSelected}
              collapsed={collapsed}
            />
          </Link>
          <Link to="/NewTurnierForm" className="LinkSide">
            <Item
              title="Turnier erstellen"
              icon={
                <QueuePlayNextIcon
                  sx={{
                    color: "rgba(255, 171, 11, 1)",
                  }}
                />
              }
              selected={selected}
              setSelected={setSelected}
              collapsed={collapsed}
            />
          </Link>

          <Link to="/liveTurnier" className="LinkSide">
            <Item
              title="Live Turnier"
              icon={
                <LiveTvIcon
                  sx={{
                    color: "rgba(255, 171, 11, 1)",
                  }}
                />
              }
              selected={selected}
              setSelected={setSelected}
              collapsed={collapsed}
            />
          </Link>
          <Link to="/ergebnisse" className="LinkSide">
            <Item
              title="Ergebnisse"
              icon={
                <ScoreboardIcon
                  sx={{
                    color: "rgba(255, 171, 11, 1)",
                  }}
                />
              }
              selected={selected}
              setSelected={setSelected}
              collapsed={collapsed}
            />
          </Link>

          <Link to="/allTurnier" className="LinkSide">
            <Item
              title="Alle Turniere"
              icon={
                <EmojiEventsIcon
                  sx={{
                    color: "rgba(112, 224, 205, 1)",
                  }}
                />
              }
              selected={selected}
              setSelected={setSelected}
              collapsed={collapsed}
            />
          </Link>

          <Link to="/ListGlobalPlayer" className="LinkSide">
            <Item
              title="Alle Spieler"
              icon={
                <Diversity3Icon
                  sx={{
                    color: "rgba(112, 224, 205, 1)",
                  }}
                />
              }
              selected={selected}
              setSelected={setSelected}
              collapsed={collapsed}
            />
          </Link>

          <Link to="/ProfilHub" className="LinkSide2">
            <Item
              title="Mein Profil"
              icon={
                <PersonOutlinedIcon
                  sx={{
                    color: "rgba(112, 224, 205, 1)",
                  }}
                />
              }
              selected={selected}
              setSelected={setSelected}
              collapsed={collapsed}
            />
          </Link>
          <Link to="/Downloads" className="LinkSide2">
            <Item
              title="mehr"
              icon={
                <FileDownloadIcon
                  sx={{
                    color: "rgba(112, 224, 205, 1)",
                  }}
                />
              }
              selected={selected}
              setSelected={setSelected}
              collapsed={collapsed}
            />
          </Link>
          <Box className="SidebarItemLogOut">
            <LogoutButton collapsed={collapsed} />
          </Box>
        </List>
      </Box>
    </Box>
  );
};

export default SidebarC;
