import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  TextField,
  IconButton,
  Typography,
  Modal,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";

function PlayerInputForm({ onSubmit }) {
  const [players, setPlayers] = useState([""]);
  const [modalOpen, setModalOpen] = useState(false);
  const inputRefs = useRef([]);
  const [pointSelection, setPointSelection] = useState(50);

  const handlePlayerChange = (index, event) => {
    const newPlayers = [...players];
    newPlayers[index] = event.target.value;
    setPlayers(newPlayers);
  };

  const addPlayer = () => {
    if (players.length < 10) {
      setPlayers([...players, ""]);
    }
  };

  const removePlayer = (index) => {
    const newPlayers = players.filter((_, i) => i !== index);
    setPlayers(newPlayers);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(
      players.filter((player) => player.trim() !== ""),
      pointSelection
    );
  };
  const handlePointSelectionChange = (event) => {
    setPointSelection(event.target.value);
  };
  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const renderPlayerInput = (player, index) => (
    <Grid item xs={12} key={index}>
      <Box display="flex" alignItems="center" mb={3}>
        <TextField
          inputRef={(el) => (inputRefs.current[index] = el)}
          className="SingleModeInput"
          value={player}
          onChange={(event) => handlePlayerChange(index, event)}
          fullWidth
        />
        <IconButton onClick={() => removePlayer(index)} tabIndex={-1}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Grid>
  );

  const playersColumn1 = players.slice(0, 4);
  const playersColumn2 = players.slice(4, 8);
  const playersColumn3 = players.slice(8);

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        height: "80%",
        width: "90%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        paddingBottom: "5px",
      }}
    >
      <div>
        <Box display="flex" justifyContent="space-between">
          <Button
            onClick={addPlayer}
            variant="contained"
            className="LoginBtnSG"
          >
            Spieler hinzufügen
          </Button>
          <Button type="submit" variant="contained" className="LoginBtnSG">
            Spiel starten
          </Button>
        </Box>
        <Box display="flex" alignItems="center" mb={2}>
          <Typography variant="h4" gutterBottom mt={2}>
            Einzelspiel
          </Typography>
          <IconButton onClick={handleModalOpen} size="small">
            <InfoIcon />
          </IconButton>
        </Box>
        <Box display="flex" alignItems="center" mb={2}>
          <Typography variant="h6">Modus</Typography>
          <FormControl fullWidth mb={2}>
            <Select
              value={pointSelection}
              onChange={handlePointSelectionChange}
            >
              <MenuItem value={100}>Standard / 100 Punkte</MenuItem>
              <MenuItem value={50}>Hardcore /50 Punkte</MenuItem>
              <MenuItem value={30}>Shots Version /1 Partie</MenuItem>
            </Select>
          </FormControl>
          <Modal open={modalOpen} onClose={handleModalClose}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                background: "linear-gradient(135deg, #2a1e36 0%, #3f354a 100%)",

                boxShadow: 24,
                p: 4,
                width: "85vw",
                maxWidth: "85vw",
                maxHeight: "85vh",
                overflowY: "auto",
              }}
            >
              <CloseIcon
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  cursor: "pointer",
                }}
                onClick={handleModalClose}
              />
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Einzelspiel
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Willkommen im Einzelspiel bei KAPKAN! Hier kannst du die
                Einzelpartien (3-10 Spieler) eintragen. Damit das Eintragen der
                Punkte zu einer Nebensache wird, haben wir dir hier die
                Möglichkeit, gegeben folgendes zu tun.
              </Typography>

              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                1. Du kannst die Punkteanzahl, die für das Spiel benötigt
                werden, auswählen. (50 oder 100)
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                2. Du kannst die Spielernamen eintragen.
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                3. Das Spiel starten.
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                4. Die Punke einfach auf dem Schieberregler nachtragen. Du
                kannst das laufende Spiel jederzeit wieder auf 0 mit den
                gleichen Spielern zurücksetzten.
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                Viel Spass beim Spielen!
              </Typography>
            </Box>
          </Modal>
        </Box>
        <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
          Spielernamen
        </Typography>
        <Box display="flex" alignItems="center" mb={2} className="endBox">
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Grid container spacing={2}>
                {playersColumn1.map((player, index) =>
                  renderPlayerInput(player, index)
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={2}>
                {playersColumn2.map((player, index) =>
                  renderPlayerInput(player, index + 4)
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={2}>
                {playersColumn3.map((player, index) =>
                  renderPlayerInput(player, index + 8)
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>
    </form>
  );
}

export default PlayerInputForm;
