import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ProgressLine from "./ProgressLine";
import { Box } from "@mui/material";
import { getAuth } from "firebase/auth";
import { doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { UsersInfoCollection, auth, uplaod } from "../../src/firebaseConfig";
import { useEffect } from "react";

function Modal({ userId, onClose }) {
  const [pageIndex, setPageIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const content = [
    {
      title: "Willkommen zur Turnier-App! von KAPKAN!",
      text1:
        "Wie ich sehe bist du Neu hier. Schaue dich doch einmal um, und erstelle dein erstes Turnier!",
      text2:
        "Wenn du Fragen hast, kannst du dich gerne an uns wenden. unter: kontakt@kapkan.ch",
      imageUrl:
        "https://cdn.shopify.com/s/files/1/0578/0770/0167/files/Logo_13d45328-c00c-40d6-b47e-8080f00ea617.png?v=1626371735",
      mobileImageUrl:
        "https://cdn.shopify.com/s/files/1/0578/0770/0167/files/Logo_13d45328-c00c-40d6-b47e-8080f00ea617.png?v=1626371735",
    },
    {
      title: "Dashboard",
      text1:
        "Das ist dein Dashboard, hier kannst du neue Turniere erstellen und von bequem starten.",
      text2: "Du kannst auch deine Turniere verwalten.",
      text3: "Das Menü hilft dir beim Navigieren über die Seite",
      imageUrl:
        "https://cdn.shopify.com/s/files/1/0578/0770/0167/files/Turtorial_Web_1.png?v=1680518397",
      mobileImageUrl:
        "https://cdn.shopify.com/s/files/1/0578/0770/0167/files/IMG_20230408_091752.jpg?v=1680945776",
    },
    {
      title: "Ein Turnier erstellen",
      text1:
        "Entscheide dich für einen Turniernamen, über welchen deine Freunde das Turnier finden können.",
      text2:
        "Wähle deinen Modus und gib noch ein paar mehr Informationen zum Austragungsort und Organisator an.",
      text3:
        "Stelle die Spieler auf. Wenn du fertig bist, klicke auf `Turnier erstellen`.",
      imageUrl:
        "https://cdn.shopify.com/s/files/1/0578/0770/0167/files/Turtorial_Web_2.png?v=1680520430",
      mobileImageUrl:
        "https://cdn.shopify.com/s/files/1/0578/0770/0167/files/IMG_20230408_093251.jpg?v=1680945776",
    },
    {
      title: "Live Turnier und Ergebnisübersicht",
      text1:
        "Wenn ein Turnier gestartet ist und Ergbnisse eingetragen sind, findest du die Übersicht aller Runden und Tische im `Live Trunier` und bei den Ergebnissen`, in Form von Charts.",
      text2:
        "Du kannst die Charts auch per Klick im Fullscreen anzeigen lassen.",
      text3:
        "Wenn keine Charts angezeigt werden, klicke auf `Daten aktualisieren`. ",

      imageUrl:
        "https://cdn.shopify.com/s/files/1/0578/0770/0167/files/Turtorial_Web_3.png?v=1680520430",
      mobileImageUrl:
        "https://cdn.shopify.com/s/files/1/0578/0770/0167/files/IMG_20230408_094139.jpg?v=1680945776",
    },
    {
      title: "Einzelspiel",
      text1:
        "Über die Seite Einzelspiel, kannst du du dein eigenes Spiel verwalten.",
      text2:
        "Spieler hinzufügen, Punktelimit festlegen, Namen eintragen und Spiel starten.",
      text3:
        "Die Ergebnisse können per Slider oder Feld nachgetragen werden, du kannst die Slider auch sperren und/oder die Seite schliessen, wir speichern deine Partie.",
      imageUrl:
        "https://cdn.shopify.com/s/files/1/0578/0770/0167/files/Turtorial_Web_4.png?v=1680520430",
      mobileImageUrl:
        "https://cdn.shopify.com/s/files/1/0578/0770/0167/files/IMG_20230408_092253.jpg?v=1680945776",
    },
    {
      title: "Dein Profil",
      text1: "Hier kannst du deine Proflinformationen einsehen und bearbeiten.",
      text2:
        "Der Stift dient zum Bearbeiten der Profilinformationen. Die Links dienen zum teilen deine Sozialen Medien an Freunde. Dafür muss dein Profil öffentlich sein.",
      text3:
        "Bei Fragen oder Problemen kannst du dich gerne über den Hilfe Knopf an uns wenden.",
      imageUrl:
        "https://cdn.shopify.com/s/files/1/0578/0770/0167/files/Turtorial_Web_5.png?v=1680520430",

      mobileImageUrl:
        "https://cdn.shopify.com/s/files/1/0578/0770/0167/files/IMG_20230408_093416.jpg?v=1680945776",
    },
    {
      title: "vieles mehr...",
      text1:
        "Unter der Seite `mehr...` findest du viele nützliche Tools, die dein Turnier und die Durchführung vereinfachen.",
      text2:
        "Den QR-Code kannst du ausdrucken und auf den Tischen platzieren, damit die Spieler die Ergebnisse selbstständig eintragen können.",
      text3:
        "Der Platzeinweiser hilft dir die Spieler per Zufall an den Tischen zu platzieren. Du kannst weitere Tools nutzen, um dein Turnier erfolgreich durchzuführen.",
      imageUrl:
        "https://cdn.shopify.com/s/files/1/0578/0770/0167/files/Turtorial_Web_6.png?v=1680520430",

      mobileImageUrl:
        "https://cdn.shopify.com/s/files/1/0578/0770/0167/files/IMG_20230408_103941.jpg?v=1680945776",
    },

    {
      title: "Profilbild auswählen",
      text1:
        "Lass uns doch wissen wer mitspielt und lade ein Profilbild hoch. *(Momentan kann es zu Verzögernungen kommen, da wir die Server aufbauen)*",
    },
  ];

  const handleNext = () => {
    if (pageIndex < content.length - 1) {
      setPageIndex(pageIndex + 1);
    }
  };

  const handlePrev = () => {
    if (pageIndex > 0) {
      setPageIndex(pageIndex - 1);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const [photoURL, setPhotoURL] = useState();
  const [imageProfile, setImageProfile] = useState(null);

  const user = auth.currentUser;
  useEffect(() => {
    if (user?.photoURL) {
      setPhotoURL(user.photoURL);
    }
  }, [user]);

  // Füge die Logik für das Ändern und Hochladen des Bildes hinzu
  const handleImageChange = async (e) => {
    if (e.target.files[0]) {
      setImageProfile(e.target.files[0]);
      await handleUplaod(e.target.files[0]);
    }
  };
  const [loading, setLoading] = useState(false);
  const handleUplaod = async (imageProfile) => {
    setLoading(true);
    const imageUrl = await uplaod(imageProfile, userId);
    if (imageUrl) {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      if (currentUser) {
        const uid = currentUser.uid;
        const q = query(UsersInfoCollection, where("userId", "==", uid));
        const userDoc = await getDocs(q);
        if (userDoc.size > 0) {
          const userRef = doc(UsersInfoCollection, userDoc.docs[0].id);
          await updateDoc(userRef, {
            image: imageUrl,
          });
          setPhotoURL(imageUrl); // Aktualisiere den lokalen State, um das neue Bild anzuzeigen.
        }
      }
    }
    setLoading(false);
  };

  // ...

  return (
    <Box className="ModalMobil">
      <Dialog className="ModalRegister" open onClose={handleClose}>
        <DialogTitle className="ModalRegisterContent">
          <ProgressLine completed={pageIndex + 1} total={content.length} />
          <br />
          {content[pageIndex].title}
        </DialogTitle>
        <DialogContent className="ModalRegisterContent">
          {pageIndex === content.length - 1 ? (
            <Box>
              <label htmlFor="image-upload">
                <Button
                  sx={{
                    mt: 2,
                    color: "white",
                    bgcolor: "#120520",
                    BoxShadow: "20px 20px 50px rgba(0,0,0,0.5)",
                  }}
                  variant="outlined"
                  component="span"
                  className="LinkTagAdd"
                >
                  Profilbild hochladen
                </Button>
              </label>
              <input
                id="image-upload"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </Box>
          ) : (
            <img
              className={`ModalRegImg ${pageIndex === 1 ? "MobileImage1" : ""}`}
              src={
                windowWidth <= 1100
                  ? content[pageIndex].mobileImageUrl
                  : content[pageIndex].imageUrl
              }
              alt="Logo"
            />
          )}
          <p>{content[pageIndex].text1}</p>
          <p />
          <p>{content[pageIndex].text2}</p>
          <p />
          <p>{content[pageIndex].text3}</p>
        </DialogContent>

        <DialogActions className="ModalRegisterContent">
          <IconButton onClick={handlePrev} disabled={pageIndex === 0}>
            <NavigateBeforeIcon />
          </IconButton>
          <IconButton
            onClick={handleNext}
            disabled={pageIndex === content.length - 1}
          >
            <NavigateNextIcon />
          </IconButton>
          {pageIndex === content.length - 1 && (
            <Button variant="contained" color="primary" onClick={handleClose}>
              Verstanden!
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
}
export default Modal;
