import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

const SpielplanModal = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [pdfURL, setPdfURL] = useState("");

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    const pdfURLs = {
      option1:
        "https://cdn.shopify.com/s/files/1/0578/0770/0167/files/Spielplan12Sp.pdf?v=1680691345",
      option2:
        "https://cdn.shopify.com/s/files/1/0578/0770/0167/files/Spielplan16.pdf?v=1680691345",
      option3:
        "https://cdn.shopify.com/s/files/1/0578/0770/0167/files/Spielplan18.pdf?v=1680691345",
      option4:
        "https://cdn.shopify.com/s/files/1/0578/0770/0167/files/Spielplan20.pdf?v=1680691345",
      option5:
        "https://cdn.shopify.com/s/files/1/0578/0770/0167/files/Spielplan24.pdf?v=1680691345",
    };
    switch (event.target.value) {
      case "option1":
        setImageURL(
          "https://cdn.shopify.com/s/files/1/0578/0770/0167/files/plan12.jpg?v=1680692059"
        );
        setPdfURL(pdfURLs[event.target.value] || "");
        break;
      case "option2":
        setImageURL(
          "https://cdn.shopify.com/s/files/1/0578/0770/0167/files/plan16.jpg?v=1680692059"
        );
        setPdfURL(pdfURLs[event.target.value] || "");
        break;
      case "option3":
        setImageURL(
          "https://cdn.shopify.com/s/files/1/0578/0770/0167/files/plan18.jpg?v=1680692059"
        );
        setPdfURL(pdfURLs[event.target.value] || "");
        break;

      case "option4":
        setImageURL(
          "https://cdn.shopify.com/s/files/1/0578/0770/0167/files/plan20.jpg?v=1680692060"
        );
        setPdfURL(pdfURLs[event.target.value] || "");
        break;
      case "option5":
        setImageURL(
          "https://cdn.shopify.com/s/files/1/0578/0770/0167/files/plan24.jpg?v=1680692060"
        );
        setPdfURL(pdfURLs[event.target.value] || "");
        break;
      default:
        setImageURL("");
    }
  };

  const downloadPdf = () => {
    if (!pdfURL) return;

    const link = document.createElement("a");
    link.href = pdfURL;
    link.target = "_blank"; // Öffnet den Link in einem neuen Tab
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box className="QRCodeSiteThree">
      <Typography variant="h4" component="h1" gutterBottom>
        Spielpläne:
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        Wähle die Anzhal der Spieler und lade einen passenden Spielplan
        herunter. Welchen du ausdrucken und aufhängen kannst.
      </Typography>
      <FormControl
        fullWidth
        variant="outlined"
        sx={{ marginTop: 2, width: "90%" }}
      >
        <InputLabel id="select-label">Anzahl Spieler</InputLabel>
        <Select
          labelId="select-label"
          value={selectedOption}
          onChange={handleSelectChange}
          label="Option auswählen"
          sx={{ paddingLeft: 2 }}
        >
          <MenuItem value="option1">12 Spieler / 3 Tische</MenuItem>
          <MenuItem value="option2">16 Spieler / 4 Tische</MenuItem>
          <MenuItem value="option3">18 Spieler / 6 Tische</MenuItem>
          <MenuItem value="option4">20 Spieler / 4 Tische</MenuItem>
          <MenuItem value="option5">24 Spieler / 6 Tische</MenuItem>
        </Select>
      </FormControl>
      {imageURL && (
        <>
          <Box
            mt={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <img
              src={imageURL}
              alt="Ausgewähltes Bild"
              style={{ width: "90%" }}
            />
          </Box>
          <Box mt={5}>
            <Button
              variant="contained"
              color="primary"
              onClick={downloadPdf}
              size="large"
            >
              Zum Drucken herunterladen
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default SpielplanModal;
