import React, { useState } from "react";
import QRCodeComponent from "../../components/QR-code";
import Platzeinweiser from "../../components/Platzeinweiser";
import SpielplanModal from "../../components/Spielpläne";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import TopbarTwo from "../../scene/global/TopBarTwo";
function Downloads() {
  const [showQRCodeModal, setShowQRCodeModal] = useState(false);

  const handleQRCodeClick = () => {
    setShowQRCodeModal(true);
  };

  const handleCloseModal = () => {
    setShowQRCodeModal(false);
  };

  const [showPlatzeinweiserModal, setShowPlatzeinweiserModal] = useState(false);

  const handleOpenPlatzClick = () => {
    setShowPlatzeinweiserModal(true);
  };

  const handleClosePlatzModal = () => {
    setShowPlatzeinweiserModal(false);
  };

  const [showSpielplanModal, setshowSpielplanModal] = useState(false);

  const handleOpenPlanClick = () => {
    setshowSpielplanModal(true);
  };

  const handleClosePlanModal = () => {
    setshowSpielplanModal(false);
  };

  return (
    <Box className="rightContainerDond">
      <Box
        sx={{
          "@media screen and (min-width: 1200px)": {
            display: "none",
            opacity: 1,
          },
        }}
      >
        <TopbarTwo />
      </Box>
      <h1>Downloads</h1>
      <div className="HeaderBox">
        <h2>Starte hier deine Planung für ein Turnier!</h2>
        <p>
          {" "}
          Hier findest du alle wichtigen Dokumente und Tools für deine
          Turnierplanung.
        </p>
        <p> Du kannst die Dokumente herunterladen und ausdrucken.</p>
        <p> Viel Spaß beim Turnier!</p>
      </div>
      <Box className="DownloadWrapper">
        <Box className="Downloads" onClick={handleOpenPlanClick}>
          <h3>Turnierplan</h3>
          <p>
            Hier findest du den Turnierplan für die Turnierabende. <br></br> Du
            kannst den Plan ausdrucken und aufhängen.
          </p>
        </Box>
        <Box className="Downloads" onClick={handleQRCodeClick}>
          <h3>QR-Code für Ergebnisse</h3>
          <p>
            Hier findest du den QR-Code, welchen du auf den Tischen verteilen
            kannst, damit die Spieler selbst das Ergebniss eintragen können.
          </p>
        </Box>
        <Box className="Downloads" onClick={handleOpenPlatzClick}>
          <h3>Platzeinweiser</h3>
          <p>
            Der "Platzeinweiser" hilft dir, die Spieler per Zufall an den
            Tischen verteilen.
          </p>
        </Box>
      </Box>

      {showQRCodeModal && (
        <Box className="ModalContainer">
          <Box className="QRCodeModal">
            <CloseIcon
              className="CloseIcon"
              onClick={handleCloseModal}
              sx={{ alignSelf: "flex-end", cursor: "pointer" }}
            />
            <QRCodeComponent />
          </Box>
        </Box>
      )}

      {showPlatzeinweiserModal && (
        <Box className="ModalContainer">
          <Box className="QRCodeModalTwo">
            <CloseIcon
              className="CloseIcon"
              onClick={handleClosePlatzModal}
              sx={{ alignSelf: "flex-end", cursor: "pointer" }}
            />
            <Platzeinweiser />
          </Box>
        </Box>
      )}
      {showSpielplanModal && (
        <Box className="ModalContainer">
          <Box className="QRCodeModalTwo">
            <CloseIcon
              className="CloseIcon"
              onClick={handleClosePlanModal}
              sx={{ alignSelf: "flex-end", cursor: "pointer" }}
            />
            <SpielplanModal />
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Downloads;
