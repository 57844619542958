import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  useTheme,
  IconButton,
} from "@mui/material";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import { tokens } from "../../theme";

import SidebarC from "../../scene/global/Sidebar";
import SubHeaderDashboard from "../../components/SubHeaderDashboard";

const data = {
  username: "Max Mustermann",
  role: "Admin",
  gewonneneTurniere: 8,
  gesamtPunkte: 3200,
  turniere: [
    {
      name: "Turnier 1",
      modus: "Option 1",
      points: 120,
      score: "Live",
      fin: 0,
    },
    {
      name: "Turnier 2",
      modus: "Option 2",
      points: 320,
      score: "Gewonnen",
      fin: 1,
    },
    {
      name: "Turnier 3",
      modus: "Option 3",
      points: 80,
      score: "3 Platz",
      fin: 1,
    },
  ],
};

function MeineTurniere() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ padding: 2, flexGrow: "2" }}>
          <SubHeaderDashboard />
          <Typography variant="h3">Meine Turniere</Typography>
          <Box sx={{ padding: 2 }}>
            <Typography variant="h4" sx={{ marginTop: 3 }}>
              Live
            </Typography>
            <Box sx={{ marginTop: 2 }}>
              <List sx={{ width: "100%" }}>
                {data.turniere.map((turnier, index) =>
                  turnier.fin === 0 ? (
                    <ListItem key={index} id="ListItemDashboard">
                      <ListItemText sx={{ width: "1vw" }}>
                        <IconButton>
                          <FiberManualRecordIcon
                            sx={{ color: turnier.fin === 1 ? "red" : "green" }}
                          />
                        </IconButton>
                      </ListItemText>
                      <ListItemText
                        primary={`${turnier.name}`}
                        sx={{ textAlign: "left" }}
                      ></ListItemText>
                      <ListItemText
                        primary={`${turnier.modus}`}
                        sx={{ textAlign: "left" }}
                      ></ListItemText>
                      <ListItemText
                        primary={`${turnier.location}`}
                      ></ListItemText>
                      <ListItemText
                        primary={`Punkte:${turnier.points}`}
                      ></ListItemText>
                      <ListItemText primary={turnier.score}></ListItemText>
                    </ListItem>
                  ) : (
                    <></>
                  )
                )}
              </List>
            </Box>
            <Typography variant="h4" sx={{ marginTop: 3 }}>
              History
            </Typography>
            <Box sx={{ marginTop: 2 }}>
              <List sx={{ width: "100%" }}>
                {data.turniere.map((turnier, index) =>
                  turnier.fin == 1 ? (
                    <ListItem key={index} id="ListItemDashboard">
                      <ListItemText sx={{ width: "1vw" }}>
                        <IconButton>
                          <FiberManualRecordIcon
                            sx={{ color: turnier.fin === 1 ? "red" : "green" }}
                          />
                        </IconButton>
                      </ListItemText>
                      <ListItemText
                        primary={`${turnier.name}`}
                        sx={{ textAlign: "left" }}
                      ></ListItemText>
                      <ListItemText
                        primary={`${turnier.modus}`}
                        sx={{ textAlign: "left" }}
                      ></ListItemText>
                      <ListItemText
                        primary={`${turnier.location}`}
                      ></ListItemText>
                      <ListItemText
                        primary={`Punkte:${turnier.points}`}
                      ></ListItemText>
                      <ListItemText primary={turnier.score}></ListItemText>
                    </ListItem>
                  ) : (
                    <></>
                  )
                )}
              </List>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default MeineTurniere;
