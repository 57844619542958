import React from "react";

function ProgressLine({ completed, total }) {
  const percentage = (completed / total) * 100;

  return (
    <div className="progress-line-container">
      <div className="progress-line" style={{ width: `${percentage}%` }} />
    </div>
  );
}

export default ProgressLine;
