import { useState } from "react";
import {
  Slider,
  TextField,
  Typography,
  Box,
  Button,
  Grid,
} from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";

function PlayerRatingForm({
  players,
  onSliderChange,
  onBackButtonClick,
  onReset,
  sliderValues,
  maxPoints,
  lockedSliders,
  onLockToggle,
  onUndo, // Hinzufügen dieser Zeile
}) {
  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: maxPoints / 2,
      label: (maxPoints / 2).toString(),
    },
    {
      value: maxPoints,
      label: maxPoints.toString(),
    },
  ];
  function handleValueInputChange(index, newValue) {
    if (newValue === "") {
      onSliderChange(index, 0);
    } else {
      onSliderChange(index, parseInt(newValue));
    }
  }

  function handleKeyPress(event) {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  }

  const handleSliderChange = (index, newValue) => {
    onSliderChange(index, newValue);
  };

  const handleLockToggle = () => {
    onLockToggle(); // Ändern Sie diese Zeile
  };

  const colors = [
    "#2a1e36",
    "rgba(139, 40, 219, 0.8)", // #8b28db
    "rgba(255, 171, 11, 0.8)", // #ffab0b
    "rgba(170, 55, 176, 0.8)", // #323232
    "rgba(112, 224, 205, 0.7)", //  #463246
  ];

  return (
    <Box className="playerRatingForm">
      <Typography variant="h4" gutterBottom className="playerRatingTitle">
        Ergebnisse
      </Typography>
      <Box className="buttonBox">
        <Button
          variant="contained"
          onClick={onBackButtonClick}
          className="backButton"
        >
          Zurück zur Spielerauswahl
        </Button>
        <Button variant="contained" onClick={onReset} className="resetButton">
          <RestartAltIcon />
        </Button>
        {/* Hinzufügen des "Rückgängigmachen"-Buttons */}
        <Button variant="contained" onClick={onUndo} className="undoButton">
          Rückgängigmachen
        </Button>
        <Button
          variant="contained"
          onClick={handleLockToggle}
          className="lockButton"
        >
          {lockedSliders ? <LockIcon /> : <LockOpenIcon />}
        </Button>
      </Box>
      <Grid container spacing={5} className="playerGridContainer">
        {players.map((player, index) => (
          <Grid item key={index} className="playerGridItem">
            <Typography className="playerName">{player}</Typography>
            <Box className="playerBox">
              <Slider
                step={1}
                marks={marks}
                disabled={lockedSliders}
                min={0}
                max={maxPoints + 10}
                defaultValue={0}
                value={
                  sliderValues[index] !== undefined ? sliderValues[index] : 0
                }
                valueLabelDisplay="on"
                onChange={(_, newValue) => handleSliderChange(index, newValue)}
                className="playerSlider"
                sx={{
                  color:
                    sliderValues[index] > maxPoints
                      ? "red"
                      : colors[index % colors.length],
                  "& .MuiSlider-valueLabel": {
                    background:
                      sliderValues[index] > maxPoints
                        ? "red"
                        : colors[index % colors.length],
                    borderRadius: "50% 50% 0% 0%",
                  },
                }}
              />
              <TextField
                inputMode="numeric"
                type="number"
                value={
                  sliderValues[index] === 0 || sliderValues[index] === undefined
                    ? ""
                    : sliderValues[index]
                }
                inputProps={{ min: 0, max: maxPoints + 10, step: 1 }}
                onChange={(event) =>
                  handleValueInputChange(index, event.target.value)
                }
                onKeyPress={handleKeyPress}
                className="playerValueInput"
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                  "& input[type=number]": {
                    "-moz-appearance": "textfield",
                  },
                }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default PlayerRatingForm;
