import { useState, useEffect } from "react";
import { Box, Typography, List, ListItem } from "@mui/material";
import { Link } from "react-router-dom";

import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import AddIcon from "@mui/icons-material/Add";

import { getAuth } from "firebase/auth";
import ButtonBox from "./buttonGrid";
import {
  getFirestore,
  getDocs,
  collection,
  query,
  where,
  doc,
  updateDoc,
  onSnapshot,
  writeBatch,
} from "@firebase/firestore";

import SubHeaderDashboard from "../../components/SubHeaderDashboard";

import { TurnierInfoCollection, db } from "../../firebaseConfig";

import Modal from "../../components/RegistModal";

function Dashboard({}) {
  const [turnierInfo, setTurnierInfo] = useState([]);

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      const uid = user.uid;
      const turnierQuery = query(
        TurnierInfoCollection,
        where("userId", "==", uid)
      );
      const unsubscribe = onSnapshot(turnierQuery, (snapshot) => {
        const updatedTurnierInfo = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setTurnierInfo(updatedTurnierInfo);
      });
      return unsubscribe;
    }
  }, []);

  const handleToggleLive = async (id, live) => {
    const firestore = getFirestore();
    const turnierRef = doc(firestore, "TurnierInfo", id);

    if (!live) {
      // Set all tournaments to not live
      const batch = writeBatch(firestore);
      turnierInfo.forEach((turnier) => {
        const turnierDocRef = doc(firestore, "TurnierInfo", turnier.id);
        batch.update(turnierDocRef, { live: false });
      });
      await batch.commit();
    }

    // Toggle the live status of the selected tournament
    await updateDoc(turnierRef, { live: !live });
  };

  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      setUserId(user.uid);
    }
  }, []);

  const [showModal, setShowModal] = useState();

  useEffect(() => {
    const fetchUserModal = async () => {
      try {
        if (userId) {
          const usersRef = collection(db, "users");
          const userQuery = query(usersRef, where("userId", "==", userId));
          const unsubscribe = onSnapshot(userQuery, (querySnapshot) => {
            if (!querySnapshot.empty) {
              const userData = querySnapshot.docs[0].data();
              setShowModal(userData.modal === true);
            }
          });
          return unsubscribe;
        }
      } catch (error) {
        console.error("Error fetching user's modal:", error);
      }
    };

    fetchUserModal();
  }, [userId]);

  const updateUserModal = async () => {
    if (userId) {
      try {
        const usersRef = collection(db, "users");
        const userQuery = query(usersRef, where("userId", "==", userId));
        const querySnapshot = await getDocs(userQuery);

        if (!querySnapshot.empty) {
          const userDocRef = doc(db, "users", querySnapshot.docs[0].id);
          await updateDoc(userDocRef, { modal: false });
        }
      } catch (error) {
        console.error("Error updating user's modal:", error);
      }
    }
  };

  return (
    <Box className="rightContent">
      {showModal && (
        <Modal
          className="ModalRegister"
          title="Willkommen zur Turnier-App! von KAPKAN!"
          text="Wie ich sehe bist du Neu hier. Schaue dich doch einmal um, und erstelle dein erstes Turnier!"
          imageUrl="https://cdn.shopify.com/s/files/1/0578/0770/0167/files/Logo_13d45328-c00c-40d6-b47e-8080f00ea617.png?v=1626371735"
          buttonLabels={["Verstanden!"]}
          userId={userId}
          onClose={updateUserModal}
        />
      )}
      <Box sx={{ display: "flex" }}>
        <Box key="subheader-dashboard" sx={{ padding: 2, flexGrow: "2" }}>
          <SubHeaderDashboard />
          <Typography variant="h2" sx={{ marginTop: 3, marginBottom: 3 }}>
            Dashboard
          </Typography>
          <ButtonBox />
          {turnierInfo.length === 0 ? (
            <Box className="NoListItemTur">
              <Typography variant="h3" sx={{ margin: "2vh" }}>
                Starte ein Turnier auf deinem Dashboard, um es hier zu sehen
              </Typography>
              {/* <Link
                className="LinkTagAdd"
                to="/newTurnierForm"
                style={{ textDecoration: "none", width: "30%" }}
                color="primary"
                aria-label="add"
                variant="extended"
              >
                <AddIcon />
                Neues Turnier hinzufügen
              </Link> */}
            </Box>
          ) : (
            <Box sx={{ marginTop: 2, mr: 2 }} className="ListTurnierInfo">
              <List className="ListTurnierInfoTitel">
                <ListItem>
                  <Typography variant="h4">Turnier</Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="h4">Modus</Typography>
                </ListItem>
                <ListItem
                  sx={{
                    "@media screen and (max-width: 1200px)": {
                      display: "none",
                      opacity: 1,
                    },
                  }}
                >
                  <Typography variant="h4">Austragungsort</Typography>
                </ListItem>
                <ListItem
                  sx={{
                    "@media screen and (max-width: 1200px)": {
                      display: "none",
                      opacity: 1,
                    },
                  }}
                >
                  <Typography variant="h4">Organisator</Typography>
                </ListItem>
                <ListItem className="ListTurnierInfoTitelLive">
                  <Typography variant="h4">Spielen</Typography>
                </ListItem>
              </List>
              {turnierInfo.map((user) => {
                return (
                  <List className="ListTurnierInfoContent" key={user.id}>
                    <ListItem>{user.name}</ListItem>
                    <ListItem>{user.mode}</ListItem>
                    <ListItem
                      sx={{
                        "@media  screen and (max-width: 1200px)": {
                          display: "none",
                          opacity: 1,
                        },
                      }}
                    >
                      {user.ort}
                    </ListItem>
                    <ListItem
                      sx={{
                        "@media screen and (max-width: 1200px)": {
                          display: "none",
                          opacity: 1,
                        },
                      }}
                    >
                      {user.admin}
                    </ListItem>
                    <ListItem className="glowDot">
                      {user.live ? (
                        <PlayCircleOutlineIcon
                          id="glowDotLive"
                          style={{ color: "green" }}
                          onClick={() => handleToggleLive(user.id, user.live)}
                        />
                      ) : (
                        <PauseCircleOutlineIcon
                          id="glowDotLive"
                          style={{ color: "red" }}
                          onClick={() => handleToggleLive(user.id, user.live)}
                        />
                      )}
                    </ListItem>
                  </List>
                );
              })}
              {turnierInfo.length === 0 && (
                <Typography variant="h4" className="NoListItemDash">
                  Du hast leider noch kein Turnier erstellt.
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default Dashboard;
