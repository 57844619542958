import React from "react";
import { Box, Typography, Button } from "@mui/material";
import QrCode from "qrcode.react";
import { saveAs } from "file-saver";

function QRCodeComponent() {
  const handleSubmit = () => {
    const canvas = document.getElementById("qr-code");
    canvas.toBlob((blob) => {
      saveAs(blob, "qr-code.png");
    });
  };

  const handleDownloadPDF = () => {
    const pdfUrl =
      "https://cdn.shopify.com/s/files/1/0578/0770/0167/files/QRCodeErgebnisse.pdf?v=1680691346";
    saveAs(pdfUrl, "QR-Code Ergebnisse.pdf");
  };

  return (
    <Box className="QRCodeSite">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            marginBottom: "2vh",
          }}
        >
          QR-Code
        </Typography>
        <Box
          sx={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <QrCode
            id="qr-code"
            value="https://kapkan.online/ergebnis" /*Das muss noch angepasst werden */
            size={250}
            fgColor="#000000"
            bgColor="#ffffff"
          />
          <Typography
            sx={{
              marginTop: "3vh",
              marginBottom: "3vh",
            }}
          >
            Du kannst diesen QR-Code ausdrucken und an den Tischen platzieren,
            damit alle selbstständig, Ihre Punkte pro Partie eintragen können.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownloadPDF}
          >
            Download
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default QRCodeComponent;
