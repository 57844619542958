import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Button,
  Snackbar,
  TextField,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkIcon from "@mui/icons-material/Link";
import EditIcon from "@mui/icons-material/Edit";
import { auth, UsersInfoCollection, uplaod } from "../../firebaseConfig";
import { getDocs, query, where, doc, updateDoc } from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import DeleteUser from "../../components/deleteUserButton";
import MuiAlert from "@mui/material/Alert";
import { forwardRef } from "react";
import TopbarTwo from "../../scene/global/TopBarTwo";
import LogoutButton from "../../components/logOutButton";

function ProfileCard() {
  const [displayName, setDisplayName] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState("");
  const user = auth.currentUser;
  const [loading, setLoading] = useState(false);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const handleEditModalClose = () => setEditModalOpen(false);

  const [imageProfile, setImageProfile] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [facebookLink, setFacebookLink] = useState("");
  const [publicProfile, setPublicProfile] = useState(true);
  const [bio, setBio] = useState("");

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const uid = user.uid;
        const q = query(UsersInfoCollection, where("userId", "==", uid));
        const userDoc = await getDocs(q);
        if (userDoc.size > 0) {
          const userData = userDoc.docs[0].data();
          setDisplayName(userData.spielerName);
          setEmail(userData.email);
          setRole(userData.role);
          setImage(userData.image);
          setInstagramLink(userData.instagramLink || "");
          setFacebookLink(userData.facebookLink || "");
          setPublicProfile(userData.publicProfile || true);
          setBio(userData.bio || "");

          // Aktualisieren Sie den Wert von photoURL basierend auf den abgerufenen Benutzerdaten.
          setPhotoURL(userData.image);
        }
      } else {
        setDisplayName("");
        setRole("");
      }
    });
    return unsubscribe;
  }, []);

  const handleUpdateProfile = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      const uid = user.uid;
      const q = query(UsersInfoCollection, where("userId", "==", uid));
      const userDoc = await getDocs(q);
      if (userDoc.size > 0) {
        const userRef = doc(UsersInfoCollection, userDoc.docs[0].id);
        await updateDoc(userRef, {
          spielerName: displayName,
          email: email,
          instagramLink: instagramLink,
          facebookLink: facebookLink,
          publicProfile: publicProfile,
          bio: bio,
        });
      }
    }
    handleEditModalClose();
  };

  const [linkSnackbarOpen, setLinkSnackbarOpen] = useState(false);
  const handleShareInstagram = () => {
    if (instagramLink) {
      window.open(`https://www.instagram.com/${instagramLink}`);
    } else {
      window.open("https://www.instagram.com/kapkan.ch/");
    }
  };

  const handleShareFacebook = () => {
    if (facebookLink) {
      window.open(`https://www.facebook.com/${facebookLink}`);
    } else {
      window.open("https://www.facebook.com/kapkan.ch/");
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText("https://kapkan.online")
      .then(() => {
        setLinkSnackbarOpen(true);
      })
      .catch((err) => console.error("Error copying text: ", err));
  };

  const handleCloseLinkSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setLinkSnackbarOpen(false);
  };

  const [photoURL, setPhotoURL] = useState();

  useEffect(() => {
    if (user?.photoURL) {
      setPhotoURL(user.photoURL);
    }
  }, [user]);

  const handleImageChange = async (e) => {
    if (e.target.files[0]) {
      setImageProfile(e.target.files[0]);
      await handleUpload(e.target.files[0]);
    }
  };

  const handleUpload = async (imageProfile) => {
    const imageUrl = await uplaod(imageProfile, user.uid);
    if (imageUrl) {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      if (currentUser) {
        const uid = currentUser.uid;
        const q = query(UsersInfoCollection, where("userId", "==", uid));
        const userDoc = await getDocs(q);
        if (userDoc.size > 0) {
          const userRef = doc(UsersInfoCollection, userDoc.docs[0].id);
          await updateDoc(userRef, {
            image: imageUrl,
          });
          setPhotoURL(imageUrl); // Aktualisieren Sie den lokalen State, um das neue Bild anzuzeigen.
        }
      }
    }
  };

  return (
    <Grid
      className="ProfilHub"
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100%", width: "100vw" }}
    >
      <Box
        sx={{
          "@media screen and (min-width: 1200px)": {
            display: "none",
            opacity: 1,
          },
        }}
      >
        <TopbarTwo />
      </Box>
      <Grid
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            backgroundColor: "none",
            borderRadius: 2,
            boxShadow: 10,
            mt: 2,
            width: "85vw",
            height: "100%",
            mb: 2,

            alignItems: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "none",
              padding: 2,
              position: "relative",
              width: "85vw",
            }}
          >
            <Box className="ProfilHubHeder">
              <Typography
                className="ProfilHubHederName"
                variant="h2"
                gutterBottom
                width="auto"
              >
                {displayName}
              </Typography>
              <Box className="avatar" src={photoURL}>
                <img
                  src={photoURL}
                  alt="avatar"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />

                <input
                  className="avatarInput"
                  id="image-upload"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              padding: 2,
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Box>
                <Typography variant="h4" gutterBottom>
                  {role}
                </Typography>
                <Typography variant="h4" gutterBottom>
                  {email}
                </Typography>
              </Box>
              <Box sx={{ mt: 5, width: "300px" }}>
                <Typography gutterBottom>{bio}</Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  mt: 2,
                }}
              >
                <IconButton aria-label="Edit" onClick={handleEditModalOpen}>
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="Instagram"
                  onClick={handleShareInstagram}
                >
                  <InstagramIcon />
                </IconButton>
                <IconButton aria-label="Facebook" onClick={handleShareFacebook}>
                  <FacebookIcon />
                </IconButton>
                <IconButton aria-label="Link" onClick={handleCopyLink}>
                  <LinkIcon />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 2,
                  mb: 2,
                }}
              >
                <Button
                  sx={{ color: "white", bgcolor: "#3f51b5" }}
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    window.location.href = "mailto:kontakt@kapkan.ch";
                  }}
                >
                  Hilfe
                </Button>

                <DeleteUser />
              </Box>
              <LogoutButton />
            </Box>
          </Box>
        </Box>
        <Dialog
          open={editModalOpen}
          onClose={handleEditModalClose}
          aria-labelledby="form-dialog-title"
          className="DialogProfileContainer"
        >
          <DialogTitle id="form-dialog-title">
            Proilnamen oder Bild ändern
          </DialogTitle>
          <DialogContent className="DialogProfile">
            <Typography variant="body2" gutterBottom sx={{ marginTop: "2vh" }}>
              Hier kannst du dein Profil bearbeiten. Wenn dein Profil auf
              öffentlich ist, können andere Spieler dich finden und diese
              Angaben sehen.
            </Typography>
            <Box>
              <label htmlFor="image-upload">
                <Button
                  sx={{
                    mt: 2,
                    color: "white",
                    bgcolor: "#120520",
                    BoxShadow: "20px 20px 50px rgba(0,0,0,0.5)",
                  }}
                  variant="outlined"
                  component="span"
                  className="LinkTagAdd"
                >
                  Profilbild hochladen
                </Button>
              </label>
              <input
                id="image-upload"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </Box>
            <label variant="h5">Spielername</label>
            <TextField
              autoFocus
              margin="dense"
              id="spielerName"
              type="text"
              fullWidth
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
            />
            <label variant="h5">Bio</label>
            <TextField
              margin="dense"
              id="bio"
              type="text"
              fullWidth
              multiline
              rows={4}
              value={bio}
              onChange={(e) => setBio(e.target.value)}
            />
            <label variant="h5">E-Mail-Adresse</label>
            <TextField
              margin="dense"
              id="email"
              type="email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label variant="h5">Dein Instagram-handle</label>
            <TextField
              margin="dense"
              id="instagramLink"
              type="text"
              placeholder="max.mustermann"
              fullWidth
              value={instagramLink}
              onChange={(e) => setInstagramLink(e.target.value)}
            />
            <label variant="h5">Dein Facebook-handle </label>{" "}
            <TextField
              margin="dense"
              id="facebookLink"
              type="text"
              placeholder="max.mustermann"
              fullWidth
              value={facebookLink}
              onChange={(e) => setFacebookLink(e.target.value)}
            />
            <FormControlLabel
              className="FormControlCheckbox"
              control={
                <Checkbox
                  className="Checkbox"
                  checked={publicProfile}
                  onChange={(e) => setPublicProfile(e.target.checked)}
                  name="publicProfile"
                  sx={{ color: "#fcfcfc" }}
                />
              }
              label="Öffentliches Profil"
            />
          </DialogContent>
          <DialogActions className="DialogAction">
            <Button onClick={handleEditModalClose}>Abbrechen</Button>
            <Button
              onClick={() => {
                handleUpdateProfile();
              }}
              sx={{ color: "white", bgcolor: "#120520" }}
            >
              Aktualisieren
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      <Snackbar
        open={linkSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseLinkSnackbar}
      >
        <Alert onClose={handleCloseLinkSnackbar} severity="success">
          Link kopiert!
        </Alert>
      </Snackbar>
    </Grid>
  );
}

const Alert = forwardRef((props, ref) => {
  return <MuiAlert ref={ref} elevation={6} variant="filled" {...props} />;
});

export default ProfileCard;
