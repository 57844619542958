import { Box } from "@mui/material";

import { Link } from "react-router-dom";

const Topbar = () => {
  return (
    <Box
      p={2}
      sx={{ width: "100%", display: "flex", justifyContent: "center" }}
    >
      <Box>
        <Link className="TopbarLink" to="/">
          <h1>KAPKAN! Online</h1>
        </Link>
      </Box>
    </Box>
  );
};

export default Topbar;
