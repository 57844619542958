import { auth } from "../src/firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import Cookies from "universal-cookie";

import React, { createContext, useEffect, useState, useCallback } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import RegistrationForm from "../src/scene/LogInFolder/register";
import RegistrationFormLogin from "../src/scene/LogInFolder/logIn";

import SidebarC from "./scene/global/Sidebar";

import ProfilHub from "./scene/ProfilHub/index";

import QrCodeGenerator from "../src/components/QR-code";

import Dashboard from "./scene/dashboard";
import NewTurnierForm from "./scene/dashboard/newTurnierForm";
import MeineTurniere from "./scene/meineTurniere";

import LiveTurnier from "./scene/liveTurnier";

import ChartComponent from "./components/ChartComponent";

import ErgebnissPage from "./scene/ergebnisse";

import AllTurnier from "./scene/allTurnier";
import ListGlobalPlayer from "./scene/ListGlobalPlayer";

import { CssBaseline, ThemeProvider, Box, Typography } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import "firebase/firestore";
import Downloads from "./scene/downloads";
import ErgebnissSubmit from "./components/ErgebnissSubmit";
import SingleGame from "./components/single/SingleGame";
import PlayerRatingForm from "./components/single/PlayerRatingForm";
import PlayerInputForm from "./components/single/PlayerInputForm";

export const UserContext = createContext({
  loggedIn: false,
  updateLoggedIn: () => {},
  userAuthToken: "",
  updateUserAuthToken: () => {},
});

const App = React.memo(() => {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const cookies = new Cookies();
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const isLoggedInCookie = cookies.get("isLoggedIn");
    return isLoggedInCookie ? JSON.parse(isLoggedInCookie) : false;
  });
  const [userToken, setUserToken] = useState(() => cookies.get("authToken"));

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
        setUserToken(user.uid); // oder user.refreshToken, je nachdem, was Sie verwenden möchten
      } else {
        setIsLoggedIn(false);
        setUserToken("");
      }
    });

    // Cleanup
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    cookies.set("isLoggedIn", JSON.stringify(isLoggedIn), { path: "/" });
    cookies.set("authToken", userToken, { path: "/" });
  }, [isLoggedIn, userToken]);

  const handleShowModal = useCallback((show) => {
    setShowModal(show);
  }, []);
  const [showModal, setShowModal] = useState(true);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <UserContext.Provider
          value={{
            loggedIn: isLoggedIn,
            updateLoggedIn: setIsLoggedIn,
            userAuthToken: userToken,
            updateUserAuthToken: setUserToken,
          }}
        >
          <BrowserRouter>
            <Box className="app">
              <Box className="content">
                {isLoggedIn && <SidebarC />}
                <Routes>
                  {isLoggedIn ? (
                    <>
                      <Route path="/" element={<Dashboard />} />
                      <Route
                        path="/newTurnierForm"
                        element={<NewTurnierForm />}
                      />
                      <Route
                        path="/meineTurniere"
                        element={<MeineTurniere />}
                      />
                      <Route path="/QR-code" element={<QrCodeGenerator />} />
                      <Route path="/liveTurnier" element={<LiveTurnier />} />
                      <Route
                        path="/ChartComponent"
                        element={<ChartComponent />}
                      />
                      <Route path="/singleGame" element={<SingleGame />} />
                      <Route
                        path="/playerRating"
                        element={<PlayerRatingForm />}
                      />
                      <Route
                        path="/playerInput"
                        element={<PlayerInputForm />}
                      />
                      <Route path="/ergebnisse" element={<ErgebnissPage />} />
                      <Route path="/ergebnis" element={<ErgebnissSubmit />} />
                      ;
                      <Route path="/allTurnier" element={<AllTurnier />} />
                      <Route
                        path="/ListGlobalPlayer"
                        element={<ListGlobalPlayer />}
                      />
                      <Route path="/login" element={<Navigate to="/" />} />
                      <Route path="/ProfilHub" element={<ProfilHub />} />
                      <Route path="/downloads" element={<Downloads />} />
                      <Route path="/*" element={<Navigate to="/" />} />
                    </>
                  ) : (
                    <>
                      <Route
                        path="/login"
                        element={<RegistrationFormLogin />}
                      />
                      <Route
                        path="/register"
                        element={
                          <RegistrationForm
                            onRegisterSuccess={() => handleShowModal(false)}
                          />
                        }
                      />
                      <Route path="/ergebnis" element={<ErgebnissSubmit />} />;
                      <Route path="/singleGame" element={<SingleGame />} />
                      <Route
                        path="/playerRating"
                        element={<PlayerRatingForm />}
                      />
                      <Route
                        path="/playerInput"
                        element={<PlayerInputForm />}
                      />
                      <Route path="/*" element={<Navigate to="/login" />} />
                    </>
                  )}
                </Routes>
              </Box>
            </Box>
          </BrowserRouter>
        </UserContext.Provider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
});

export default App;
