import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "firebase/compat/database"; //v9

import { Box, Typography, Button } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDocs, query, where, collection } from "firebase/firestore";

import { db } from "../firebaseConfig";

const UsersInfoCollection = collection(db, "users");

const SubHeaderDashboard = () => {
  const [displayName, setDisplayName] = useState("");
  const [role, setRole] = useState("");
  const [profileImage, setProfileImage] = useState(null); // Setzen Sie den Anfangswert auf null
  const auth = getAuth();
  const placeholder =
    "https://cdn.shopify.com/s/files/1/0578/0770/0167/files/Logo_13d45328-c00c-40d6-b47e-8080f00ea617.png?v=1626371735";

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const uid = user.uid;
        const q = query(UsersInfoCollection, where("userId", "==", uid));
        const userDoc = await getDocs(q);
        if (userDoc.size > 0) {
          const userData = userDoc.docs[0].data();
          setDisplayName(userData.spielerName);
          setRole(userData.role);
          setProfileImage(userData.image);
        }
      } else {
        setDisplayName("");
        setRole("");
      }
    });
    return unsubscribe;
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 2,
        width: "100%",
        paddingRight: "3vw",
        "@media screen and (max-width: 760px)": {
          flexDirection: "column",
          alignItems: "flex-start",
          paddingRight: 0,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",

          "@media screen and (max-width: 600px)": {
            marginBottom: 1,
            width: "90vw",
          },
        }}
      >
        <img
          src={profileImage || placeholder}
          alt="Du hast noch kein Profilbild hochgeladen."
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            marginRight: "1rem",
          }}
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h2">{displayName}</Typography>
          <Typography variant="h4">{role}</Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",

          "@media screen and (max-width: 760px)": {
            alignItems: "center",
            width: "90vw",
          },
        }}
      >
        {/*  <Link to="/newTurnierForm" className="LinkSide">
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ textDecorationLine: "none" }}
          >
            Turnier hinzufügen
          </Button>
        </Link> */}
      </Box>
    </Box>
  );
};

export default SubHeaderDashboard;
