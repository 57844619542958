import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Input,
  Label,
  FormControl,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import { auth } from "../../firebaseConfig";
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import Cookies from "universal-cookie/cjs/Cookies";
import Topbar from "../../scene/global/TopBar";
import ScoreboardIcon from "@mui/icons-material/Scoreboard";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";

import { UserContext } from "../../App";

function RegistrationFormLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);

  const { loggedIn, updateLoggedIn, userAuthToken, updateUserAuthToken } =
    useContext(UserContext);

  const handleEmailChange = (event) => setEmail(event.target.value);
  const handlePasswordChange = (event) => setPassword(event.target.value);
  const cookies = new Cookies();

  const handleLogin = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      const authObject = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      if (authObject.user.isAnonymous == false) {
        updateLoggedIn(true);
        updateUserAuthToken(authObject.user.accessToken);
        cookies.set("authToken", authObject.user.accessToken);
      }
      setLoading(false);
    } catch (error) {
      setError("Ups, da ist etwas schief gelaufen..?!");
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin(event);
    }
  };

  return (
    <Box className="RegContainer">
      <Topbar className="topBar" />

      <Typography variant="h4" sx={{ textAlign: "center", mb: "20px" }}>
        Login
      </Typography>
      <Box className="inputFieldRegisterBox">
        <FormControl className="inputFieldRegisterForm">
          <Input
            className="inputFieldRegister"
            type="email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          />
        </FormControl>
        <FormControl className="inputFieldRegisterForm">
          <Input
            className="inputFieldRegister"
            type="password"
            placeholder="Passwort"
            value={password}
            onChange={handlePasswordChange}
            onKeyPress={handleKeyPress}
          />
        </FormControl>
      </Box>
      {error && <Typography variant="body2">{error}</Typography>}
      <Box className="LoginBtnContainer">
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleLogin}
          disabled={loading}
        >
          {loading ? "lädt..." : "Login"}
        </Button>
      </Box>
      <Box
        sx={{
          textAlign: "center",
          mt: "20px",
          color: "rgba(0, 0, 0, 0.54)",
        }}
      >
        <Typography variant="body2" sx={{ color: "#e0e0e0" }}>
          Keinen Account bis jetzt?{" "}
          <Link className="LinkUserSide" to="/register">
            registriere dich hier
          </Link>
        </Typography>
      </Box>
      <Typography
        variant="h4"
        sx={{ textAlign: "center", mb: "20px", mt: "20px" }}
      >
        Gratishelfer
      </Typography>
      <Box
        className="LoginBtnContainer"
        sx={{
          marginTop: "5vh",
          marginBottom: "5vh",
          padding: "0 20px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Link to="/ergebnis" className="LinkSide">
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<ScoreboardIcon />}
            sx={{
              marginRight: "20px",
              color: "rgba(255, 171, 11, 1)",
            }}
          >
            Ergebnisse
          </Button>
        </Link>
        <Link to="/singleGame" className="LinkSide">
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<TableRestaurantIcon />}
            sx={{
              color: "rgba(170, 55, 176, 1)",
            }}
          >
            Einzelspiel!
          </Button>
        </Link>
      </Box>
    </Box>
  );
}

export default RegistrationFormLogin;
