import React, { useState } from "react";
import { Grid, Box, Button, Typography } from "@mui/material";
import Platzeinweiser from "../../components/Platzeinweiser";

import LiveTvIcon from "@mui/icons-material/LiveTv";
import StadiumIcon from "@mui/icons-material/Stadium";
import SendTimeExtensionIcon from "@mui/icons-material/SendTimeExtension";
import ScoreboardIcon from "@mui/icons-material/Scoreboard";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import NextPlanIcon from "@mui/icons-material/NextPlan";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import CloseIcon from "@mui/icons-material/Close";

const ButtonBox = () => {
  const [openPlatzeinweiser, setOpenPlatzeinweiser] = useState(false);

  const handleOpenPlatzeinweiser = () => {
    setOpenPlatzeinweiser(true);
  };

  const handleClosePlatzeinweiser = () => {
    setOpenPlatzeinweiser(false);
  };
  return (
    <Grid container spacing={2} className="buttonBoxContainer" maxWidth="100%">
      {/* Erste Reihe */}
      <Grid item xs={6}>
        <Box className="würfelBox">
          <Button className="singleModeButton" href="/singleGame">
            <TableRestaurantIcon
              sx={{
                color: "rgba(170, 55, 176, 1)",
                fontSize: "3rem",
              }}
            />
            <Typography
              sx={{
                color: "rgba(170, 55, 176, 1)",
              }}
            >
              Single Mode
            </Typography>
          </Button>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box className="trophaeBox">
          <Button className="turnierErstellenButton" href="/newTurnierForm">
            <EmojiEventsIcon
              sx={{
                color: "rgba(255, 171, 11, 1)",
                fontSize: "3rem",
              }}
            />
            <Typography
              sx={{
                color: "rgba(255, 171, 11, 1)",
              }}
            >
              Turnier erstellen
            </Typography>
          </Button>
        </Box>
      </Grid>

      {/* Zweite Reihe */}
      <Grid item xs={3}>
        <Box className="profilBox">
          <Button className="profilIconButton" href="/ProfilHub">
            <PersonOutlinedIcon
              sx={{
                color: "rgba(112, 224, 205, 1)",
                fontSize: "1.5rem",
              }}
            />
            <Typography
              sx={{
                color: "rgba(112, 224, 205, 1)",
              }}
            >
              {" "}
              Profil
            </Typography>{" "}
          </Button>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box className="peopleBox">
          <Button className="peopleIconButton" href="/ListGlobalPlayer">
            <Diversity3Icon
              sx={{
                color: "rgba(112, 224, 205, 1)",
                fontSize: "1.5rem",
              }}
            />
            <Typography
              sx={{
                color: "rgba(112, 224, 205, 1)",
              }}
            >
              Alle Spieler
            </Typography>
          </Button>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box className="liveBox">
          <Button className="liveIconButton" href="/liveTurnier">
            <LiveTvIcon
              sx={{
                color: "rgba(255, 171, 11, 1)",
                fontSize: "1.5rem",
              }}
            />
            <Typography
              sx={{
                color: "rgba(255, 171, 11, 1)",
              }}
            >
              Live Turnier
            </Typography>
          </Button>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box className="trophäeIconBox">
          <Button className="trophäeIconButton" href="/ergebnisse">
            <ScoreboardIcon
              sx={{
                color: "rgba(255, 171, 11, 1)",
                fontSize: "1.5rem",
              }}
            />
            <Typography
              sx={{
                color: "rgba(255, 171, 11, 1)",
              }}
            >
              Punkte anzeigen
            </Typography>
          </Button>
        </Box>
      </Grid>

      {/* Dritte Reihe */}
      <Grid item xs={3}>
        <Box className="downloadsBox">
          <Button className="mehrButton" href="/downloads">
            <SendTimeExtensionIcon
              sx={{
                color: "rgba(112, 224, 205, 1)",
                fontSize: "1.5rem",
              }}
            />
            <Typography
              sx={{
                color: "rgba(112, 224, 205, 1)",
              }}
            >
              Mehr
            </Typography>
          </Button>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box className="downloadsBox">
          <Button className="mehrButton" href="/allTurnier">
            <StadiumIcon
              sx={{
                color: "rgba(112, 224, 205, 1)",
                fontSize: "1.5rem",
              }}
            />
            <Typography
              sx={{
                color: "rgba(112, 224, 205, 1)",
              }}
            >
              Alle Turniere
            </Typography>
          </Button>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box className="settingsBox">
          <Button className="einstellungenButton" href="/ergebnis">
            <LibraryAddIcon
              sx={{
                color: "rgba(255, 171, 11, 1)",
                fontSize: "2rem",
              }}
            />
            <Typography
              sx={{
                color: "rgba(255, 171, 11, 1)",
                fontSize: "0.7rem",
              }}
            >
              Ergebnisse eintragen
            </Typography>
          </Button>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box className="settingsBox">
          <Button
            className="einstellungenButton"
            onClick={handleOpenPlatzeinweiser}
          >
            <NextPlanIcon
              sx={{
                color: "rgba(255, 171, 11, 1)",
                fontSize: "2.5rem",
              }}
            />
            <Typography
              sx={{
                color: "rgba(255, 171, 11, 1)",
              }}
            >
              nächste Runde
            </Typography>
          </Button>
        </Box>
      </Grid>
      {openPlatzeinweiser && (
        <Box className="ModalContainer">
          <Box className="QRCodeModalTwo">
            <CloseIcon
              className="CloseIcon"
              onClick={handleClosePlatzeinweiser}
              sx={{ alignSelf: "flex-end", cursor: "pointer" }}
            />
            <Platzeinweiser open={openPlatzeinweiser} />
          </Box>
        </Box>
      )}
    </Grid>
  );
};

export default ButtonBox;
