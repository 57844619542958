import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";

import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  limit,
} from "firebase/firestore";

import { db } from "../../firebaseConfig";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Input,
  InputLabel,
} from "@mui/material";
import TopBarThree from "../global/TopBarThree";

const RegistrationForm = ({ onRegisterSuccess }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [spielerName, setSpielerName] = useState("");
  const [userType, setUserType] = useState("Spieler");
  const [error, setError] = useState(null);

  const auth = getAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      // Überprüfen, ob der Benutzername bereits existiert
      const usernameQuery = query(
        collection(db, "users"),
        where("spielerName", "==", spielerName),
        limit(1)
      );
      const usernameSnapshot = await getDocs(usernameQuery);

      if (!usernameSnapshot.empty) {
        setError(
          "Der Benutzername ist bereits vergeben. Bitte wähle einen anderen Benutzernamen."
        );
        return;
      }

      onRegisterSuccess(false);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Box className="RegContainer">
      <Grid
        container
        className="GridItemMargin"
        spacing={1}
        justifyContent="center"
        alignItems="center"
      >
        <Box>
          <TopBarThree />
          <Typography component="h1" variant="h5" className="TypographyColor">
            Registriere dich hier, um eigene Turniere zu verwalten!
          </Typography>
        </Box>
        <Box className="GridContainerMargin">
          <form onSubmit={handleSubmit}>
            <Box className="GridContainerMarginGrid">
              <Box>
                <InputLabel className="InputLabel" htmlFor="turniername">
                  Wie lautet dein Spielername?
                </InputLabel>
                <TextField
                  className="inputFieldRegister2"
                  variant="outlined"
                  required
                  type="text"
                  value={spielerName}
                  onChange={(e) => setSpielerName(e.target.value)}
                  InputProps={{}}
                />
              </Box>
              <Box>
                <InputLabel className="InputLabel" htmlFor="email">
                  Wie lautet deine E-Mail?
                </InputLabel>
                <Input
                  className="inputFieldRegister"
                  variant="outlined"
                  type="email"
                  required
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Box>
              <Box>
                <InputLabel className="InputLabel" htmlFor="password">
                  Dein Passwort
                </InputLabel>
                <Input
                  className="inputFieldRegister"
                  label="Passwort"
                  type="password"
                  required
                  fullWidth
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Box>

              <Box className="LoginBtnContainer">
                <Button
                  className="LoginBtn"
                  type="submit"
                  size="large"
                  variant="contained"
                  color="primary"
                >
                  Registrieren
                </Button>
              </Box>
              {error && (
                <Box>
                  <Typography color="error">{error}</Typography>
                </Box>
              )}
            </Box>
          </form>
        </Box>
        <Grid className="BoxPaddingTop">
          <Typography variant="body2" className="TypographyColor">
            hast du bereits einen Account?{" "}
            <Link to="/logInFolder" className="LinkUserSide">
              logge dich hier ein
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RegistrationForm;
