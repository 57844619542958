import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { Snackbar, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TopBarTwo from "../scene/global/TopBarTwo";
import { styled } from "@mui/system";
import {
  getDocs,
  addDoc,
  collection,
  where,
  query,
  updateDoc,
} from "@firebase/firestore";
import { db, playerInfoCollection } from "../firebaseConfig";

const StyledAutocomplete = styled(Autocomplete)(() => ({
  "& .MuiAutocomplete-endAdornment": {
    display: "none",
    width: "0 !important",
  },
  "& .MuiInputBase-root": {
    width: "100%",
  },
  "& .MuiOutlinedInput-input": {
    padding: "12px 14px",
    paddingRight: 0,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    margin: 0,
  },
}));

function ErgebnissSubmit() {
  const [turnier, setTurnier] = useState("");
  const [runde, setRunde] = useState("");
  const [tisch, setTisch] = useState("");
  const [spielerName, setSpielerName] = useState("");
  const [punkte, setPunkte] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);


  // Lade den gespeicherten Wert des Turniers aus dem localStorage (falls vorhanden)
  useEffect(() => {
    const savedTurnier = localStorage.getItem("selectedTurnier");
    if (savedTurnier) {
      setTurnier(savedTurnier);
    }
  }, []);

  const createNewPoints = async () => {
    if (!turnier || !spielerName || !punkte) {
      alert("Ups, du hast vergessen, das Formular komplett auszufüllen!");
      return;
    }
    // Speichere den ausgewählten Turniernamen im localStorage
    localStorage.setItem("selectedTurnier", turnier);
    // Check if player already exists
    const playerQuery = query(
      playerInfoCollection,
      where("turnier", "==", turnier),
      where("spielerName", "==", spielerName),
      where("runde", "==", runde)
    );
    const playerQuerySnapshot = await getDocs(playerQuery);
    if (playerQuerySnapshot.size > 0) {
      const existingPlayerRef = playerQuerySnapshot.docs[0].ref;
      const existingPlayerData = playerQuerySnapshot.docs[0].data();

      var oldpunkte = parseInt(existingPlayerData.punkte);
      var newpunkte = parseInt(punkte);

      await updateDoc(existingPlayerRef, {
        punkte: oldpunkte + newpunkte,
      });
    } else {
      try {
        await addDoc(playerInfoCollection, {
          turnier,
          runde,
          spielerName,
          punkte,
        });
      } catch (error) {
        alert(
          "Ups, Es gibt gerade einen Fehler beim Erstellen deines Turniers:"
        );
      }
    }

    setRunde("");
    setTisch("");
    setSpielerName("");
    setPunkte("");

    setOpenSnackbar(true);
  };

  //Autocomplet Function für Turniernamen
  const [availableTurniers, setAvailableTurniers] = useState([]);

  const fetchTurniers = async () => {
    // Fetch Turniernamen von Ihrer Datenbank
    const turnierSnapshot = await getDocs(collection(db, "TurnierInfo")); // Ersetzen Sie dies durch die entsprechende Sammlung in Ihrer Datenbank
    const turnierNames = turnierSnapshot.docs.map((doc) => doc.data().name);
    setAvailableTurniers(turnierNames);
  };

  useEffect(() => {
    fetchTurniers();
  }, []);

  const [availablePlayers, setAvailablePlayers] = useState([]);

  const fetchPlayers = async (availableTurniers) => {
    // Überprüfen, ob ein Turniername angegeben wurde
    if (!availableTurniers) return;

    // Fetch Spielernamen von Ihrer Datenbank für das angegebene Turnier
    const turnierRef = collection(db, "TurnierInfo");
    const turnierQuery = query(
      turnierRef,
      where("name", "==", availableTurniers)
    );
    const playerSnapshot = await getDocs(turnierQuery);
    const playerNames = playerSnapshot.docs
      .map((doc) => doc.data().player)
      .flat();
    setAvailablePlayers(playerNames);
  };
  useEffect(() => {
    fetchPlayers(turnier);
  }, [turnier]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      createNewPoints(event);
    }
  };

  return (
    <Box className="">
      <TopBarTwo />
      <Box className="rightContainerS">
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{ background: "#74bc71", color: "#fff" }}
          onClose={handleCloseSnackbar}
          message="Super! Dein Ergebnis wurde erfolgreich eingetragen!"
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
        <Grid container spacing={1} className="ErgebnisseContainerS">
          <Grid item xs={12}>
            <Typography className="TextFieldInputUserT" variant="h4">
              Ergebnis eintragen
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              htmlFor="player-autocomplete"
              className="TextFieldInputUserT"
            >
              An welchem Turnier nimmst du teil?
            </Typography>
            <StyledAutocomplete
              disableClearable
              freeSolo
              className="TextFieldInputUserS"
              id="turnier-autocomplete"
              options={availableTurniers}
              value={turnier}
              onChange={(event, newValue) => {
                setTurnier(newValue);
                localStorage.setItem("selectedTurnier", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
  <Typography
    htmlFor="round-select"
    className="TextFieldInputUserT"
  >
    Wähle die Runde aus
  </Typography>
  <Select
    value={runde}
    onChange={(event) => setRunde(event.target.value)}
    className="TextFieldInputUserS"
  >
    <MenuItem value={"Runde 1"}>Runde 1</MenuItem>
    <MenuItem value={"Runde 2"}>Runde 2</MenuItem>
    <MenuItem value={"Runde 3"}>Runde 3</MenuItem>
    <MenuItem value={"Runde 4"}>Runde 4</MenuItem>
    <MenuItem value={"Runde 5"}>Runde 5</MenuItem>
    <MenuItem value={"Runde 6"}>Runde 6</MenuItem>
    <MenuItem value={"Runde 7"}>Runde 7</MenuItem>
  </Select>
</Grid>
          <Grid item xs={12}>
            <Typography
              htmlFor="player-autocomplete"
              className="TextFieldInputUserT"
            >
              Wie ist dein Spielername?
            </Typography>
            <StyledAutocomplete
              disableClearable
              freeSolo
              className="TextFieldInputUserS"
              label="Wie ist dein Spielername?"
              options={availablePlayers}
              value={spielerName}
              onChange={(event, newValue) => setSpielerName(newValue)}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              htmlFor="player-autocomplete"
              className="TextFieldInputUserT"
            >
              Wie viel Punkte hast du gerade erspielt?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="selectInputUserErgS"
              type="text"
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                min: 0,
                max: 99,
              }}
              value={punkte}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue.length <= 2 && /^\d*$/.test(inputValue)) {
                  setPunkte(inputValue);
                }
              }}
              onKeyPress={handleKeyPress}
            />
          </Grid>

          <Grid item xs={12} className="PaddingClassBottom">
            <Button
              variant="contained"
              color="primary"
              onClick={createNewPoints}
            >
              Ergebnis eintragen
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default ErgebnissSubmit;
