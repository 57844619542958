import { Box, Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link, useNavigate } from "react-router-dom";
import GridViewIcon from "@mui/icons-material/GridView";

const TopBarThree = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Box
      p={2}
      sx={{
        width: "100%",

        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Link className="TopbarLink" to="/">
        <Button className="turnierErstellenButtonTwo">
          <GridViewIcon
            sx={{
              color: "rgba(255, 171, 11, 0.8)",
              fontSize: "2.5rem",
            }}
          />
        </Button>
      </Link>
      <h1>KAPKAN! Online</h1>
    </Box>
  );
};

export default TopBarThree;
