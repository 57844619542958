import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  FormGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import {
  getDocs,
  collection,
  addDoc,
  doc,
  getDoc,
  updateDoc,
  query,
  where,
} from "@firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { db } from "../firebaseConfig";
import { Autocomplete } from "@mui/material";
import { playerInfoCollection, TurnierInfoCollection } from "../firebaseConfig";

const Platzeinweiser = () => {
  const [spielerListe, setSpielerListe] = useState([]);
  const [maxTischNummer, setMaxTischNummer] = useState("");
  const [tableAssignments, setTableAssignments] = useState([]);
  const [availablePlayers, setAvailablePlayers] = useState([]);
  const [tournamentList, setTournamentList] = useState([]);
  const [selectedTournament, setSelectedTournament] = useState("");
  const [roundNumber, setRoundNumber] = useState("");

  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);

  const handleRoundNumberChange = (event) => {
    setRoundNumber(parseInt(event.target.value));
  };

  const handleSpielerListeChange = (event, value) => {
    setSpielerListe(value);
  };

  const handleMaxTischNummerChange = (event) => {
    setMaxTischNummer(parseInt(event.target.value));
  };

  const distributePlayers = () => {
    let assignments = [];
    for (let i = 0; i < maxTischNummer; i++) {
      assignments[i] = [];
    }

    spielerListe.forEach((spieler, index) => {
      const tableIndex = index % maxTischNummer;
      assignments[tableIndex].push(spieler);
    });

    setTableAssignments(assignments);
  };

  const fetchPlayers = async () => {
    const playerSnapshot = await getDocs(collection(db, "TurnierInfo"));
    const playerNames = playerSnapshot.docs
      .map((doc) => doc.data().player)
      .flat();
    setAvailablePlayers(playerNames);
  };

  const fetchTournaments = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      const uid = user.uid;
      const turnierQuery = query(
        TurnierInfoCollection,
        where("userId", "==", uid)
      );
      const tournamentSnapshot = await getDocs(turnierQuery);
      const tournaments = tournamentSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTournamentList(tournaments);
    }
  };

  const fetchActiveRound = async () => {
    if (!selectedTournament) return;

    const tournamentRef = doc(db, "TurnierInfo", selectedTournament);
    const tournamentSnapshot = await getDoc(tournamentRef);
    const activeRound = tournamentSnapshot.data().activeRound;

    setRoundNumber(activeRound);
  };

  const handleSelectedTournamentChange = (event) => {
    setSelectedTournament(event.target.value);
  };

  const handleTournamentSelect = (event) => {
    const selectedTournamentId = event.target.value;
    setSelectedTournament(selectedTournamentId);

    const selectedTournament = tournamentList.find(
      (tournament) => tournament.id === selectedTournamentId
    );
    if (selectedTournament) {
      setSpielerListe(selectedTournament.player);
    }
  };

  useEffect(() => {
    fetchPlayers();
    fetchTournaments();
    fetchActiveRound();
  }, [selectedTournament]);

  /* Die Spielernamen werden nach shuffle an den plätzen verteilt:  */
  const addPlayerToCollection = async () => {
    if (spielerListe.length === 0) {
      alert("Es sind keine Spieler hinzugefügt worden.");
      return;
    }

    // Finde das ausgewählte Turnierobjekt
    const selectedTournamentObj = tournamentList.find(
      (tournament) => tournament.id === selectedTournament
    );

    if (!selectedTournamentObj) {
      alert("Bitte wählen Sie ein Turnier aus.");
      return;
    }

    try {
      spielerListe.forEach(async (spielerName, index) => {
        const tableIndex = index % maxTischNummer;

        await addDoc(playerInfoCollection, {
          spielerName: spielerName,
          runde: `Runde ${roundNumber}`,
          tisch: `Tisch ${tableIndex + 1}`,
          punkte: 0,
          turnier: selectedTournamentObj.name, // Verwende den Namen des ausgewählten Turniers statt der ID
        });
      });

      // Aktualisieren Sie das ausgewählte Turnierdokument mit dem neuen Wert von activeRound
      const selectedTournamentRef = doc(db, "TurnierInfo", selectedTournament);
      await updateDoc(selectedTournamentRef, {
        activeRound: roundNumber,
      });

      alert("Spieler wurden erfolgreich zur Sammlung hinzugefügt!");
    } catch (error) {
      alert(
        "Ups, es gab einen Fehler beim Hinzufügen der Spieler zur Sammlung."
      );
    }
  };

  return (
    <Box className="QRCodeSiteTwo">
      <Typography variant="h4">Platzeinweiser</Typography>
      <Box sx={{ margin: 2 }}>
        <Typography variant="body1">
          Hier können Sie alle Spieler per Zufall auf den Tischen verteilen und
          die nächste Runde starten.{" "}
        </Typography>{" "}
        <br></br>
        <Typography variant="body1">
          1. Suchen Sie das entsprechende Turnier, ändern Sie die Runde auf die
          nächste (+1) und tragen Sie die fehlende Spieler ein.
        </Typography>{" "}
        <br></br>
        <Typography variant="body1">
          2. Geben Sie die Anzahl der Tische an. und drücken "Spieler
          verteilen", danach können Sie mit "Runde starten" die nächste Runde
          beginnen auf dem Monitor.
        </Typography>
      </Box>

      <FormControl component="fieldset" sx={{ width: "100%" }}>
        <FormGroup>
          <FormControl sx={{ marginBottom: 2, minWidth: 120 }}>
            <InputLabel htmlFor="tournament-select">Turnier</InputLabel>
            <Select
              value={selectedTournament}
              onChange={handleTournamentSelect}
              label="Turnier"
              inputProps={{
                id: "tournament-select",
              }}
            >
              {tournamentList.map((tournament) => (
                <MenuItem key={tournament.id} value={tournament.id}>
                  {tournament.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ margin: 2, display: "flex" }}>
            <Typography
              sx={{ paddingleft: 2, marginLeft: 2, mb: 2 }}
              variant="body1"
            >
              Wir starten als nächstes in die diese Runde:
            </Typography>
            <TextField
              sx={{ paddingleft: 2, marginLeft: 1, marginRight: 1 }}
              type="text"
              variant="outlined"
              inputProps={{
                inputMode: "numeric",
                pattern: "^[0-9]{1,2}$",
                min: 1,
                max: 99,
                maxLength: 2,
              }}
              value={roundNumber}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue === "") {
                  setRoundNumber(""); // Setzen Sie den Wert auf "" zurück, wenn das Eingabefeld leer ist
                } else if (/^[0-9]{0,2}$/.test(inputValue)) {
                  handleRoundNumberChange(e);
                }
              }}
            />
          </Box>
          <Autocomplete
            multiple
            freeSolo
            options={availablePlayers}
            value={spielerListe}
            onChange={handleSpielerListeChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Spielerliste"
                sx={{ marginTop: 2, marginBottom: 2 }}
              />
            )}
          />
          <Typography sx={{ paddingleft: 2, marginLeft: 2 }} variant="body1">
            Anzahl der Tische
          </Typography>
          <TextField
            sx={{ paddingleft: 2, marginLeft: 1, marginRight: 1 }}
            type="text"
            variant="outlined"
            inputProps={{
              inputMode: "numeric",
              pattern: "^[0-9]{1,2}$",
              min: 0,
              max: 99,
              maxLength: 2,
            }}
            value={maxTischNummer}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue === "") {
                setMaxTischNummer(); // Setzen Sie den Wert auf 1 zurück, wenn das Eingabefeld leer ist
              } else if (/^[0-9]{0,2}$/.test(inputValue)) {
                handleMaxTischNummerChange(e);
              }
            }}
          />
          <Button
            onClick={distributePlayers}
            variant="contained"
            color="primary"
          >
            Spieler verteilen
          </Button>
        </FormGroup>
      </FormControl>

      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-around"
        alignItems="flex-end"
        sx={{ marginBottom: 4 }}
      >
        <TableContainer component={Box}>
          <Table>
            <TableHead>
              <TableRow>
                {tableAssignments.map((_, index) => (
                  <TableCell key={index}>Tisch {index + 1}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {tableAssignments.map((table, index) => (
                  <TableCell key={index}>{table.join(", ")}</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {tableAssignments.length > 0 && (
          <Button
            sx={{ marginTop: 2 }}
            variant="contained"
            color="primary"
            onClick={addPlayerToCollection}
          >
            Nächste Runde starten!
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Platzeinweiser;
