import React, { useState, useEffect, useCallback, memo } from "react";
import "firebase/database";
import { Link } from "react-router-dom";

import {
  Modal,
  Button,
  Box,
  TextField,
  Typography,
  List,
  ListItem,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";

import SubHeaderTurnierErgebnisse from "../../components/SubHeaderTurnier";
import TopbarTwo from "../../scene/global/TopBarTwo";

//Firestoreimport
import {
  getDocs,
  query,
  where,
  updateDoc,
  onSnapshot,
} from "@firebase/firestore";

import {
  TurnierInfoCollection,
  playerInfoCollection,
} from "../../firebaseConfig";

import { getAuth } from "firebase/auth";

const ErgebnissPage = memo(() => {
  // Beispieldaten für die Liste von Einträgen
  const [eintraege, setEintraege] = useState([]); // eintraege is a state variable
  const [turnierName, setTurnierName] = useState("");
  const [spielerNamen, setSpielerNamen] = useState([]);
  const [ergebnisse, setErgebnisse] = useState([]);
  const [currentRound, setCurrentRound] = useState(0);
  const [currentTable, setCurrentTable] = useState(0);

  const maxRound = 10; // Anzahl der maximalen Tische anpassen

  const incrementRound = () => {
    if (currentRound === -1) {
      setCurrentRound(1);
    } else if (currentRound < maxRound) {
      setCurrentRound(currentRound + 1);
    } else {
      setCurrentRound(-1);
    }
  };

  const decrementRound = () => {
    if (currentRound === -1) {
      setCurrentRound(maxRound);
    } else if (currentRound > 1) {
      setCurrentRound(currentRound - 1);
    } else {
      setCurrentRound(-1);
    }
  };
  const maxTables = 6; // Anzahl der maximalen Tische anpassen

  const incrementTable = () => {
    if (currentTable === -1) {
      setCurrentTable(1);
    } else if (currentTable < maxTables) {
      setCurrentTable(currentTable + 1);
    } else {
      setCurrentTable(-1);
    }
  };

  const decrementTable = () => {
    if (currentTable === -1) {
      setCurrentTable(maxTables);
    } else if (currentTable > 1) {
      setCurrentTable(currentTable - 1);
    } else {
      setCurrentTable(-1);
    }
  };
  const onRoundTableChange = useCallback(
    (round, table) => {
      // Logik zum Filtern der Ergebnisse basierend auf der aktuellen Runde und dem aktuellen Tisch
      const filteredErgebnisse = ergebnisse.filter((ergebnis) => {
        const roundMatch = round <= 0 || ergebnis.runde === `Runde ${round}`;
        const tableMatch = table <= 0 || ergebnis.tisch === `Tisch ${table}`;
        return roundMatch && tableMatch;
      });
      setEintraege(filteredErgebnisse);
    },
    [ergebnisse]
  );

  useEffect(() => {
    onRoundTableChange(currentRound, currentTable);
  }, [currentRound, currentTable, onRoundTableChange]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(playerInfoCollection, where("turnier", "==", turnierName)),
      (snapshot) => {
        const newErgebnisse = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setErgebnisse(newErgebnisse);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [turnierName]);

  /* Modal */
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);

  const [spielerName, setSpielerName] = useState("");
  const [runde, setRunde] = useState("");
  const [tisch, setTisch] = useState("");
  const [punkte, setPunkte] = useState("");

  const [currentTurnier, setCurrentTurnier] = useState("");
  const [liveTurniere, setLiveTurniere] = useState([]);
  const [currentTurnierIndex, setCurrentTurnierIndex] = useState(0);

  useEffect(() => {
    const getLiveTurnier = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const uid = user.uid;
        const q = query(TurnierInfoCollection, where("live", "==", true));
        const turnierDoc = await getDocs(q);
        if (turnierDoc.size > 0) {
          const turniere = turnierDoc.docs.map((doc) => doc.data());
          setLiveTurniere(turniere);
          if (turniere[currentTurnierIndex]) {
            setTurnierName(turniere[currentTurnierIndex].name);
            setCurrentTurnier(turniere[currentTurnierIndex].name);
          }
        }
      }
    };

    getLiveTurnier();
  }, [currentTurnierIndex]);

  const incrementTurnierIndex = () => {
    setCurrentTurnierIndex((prevIndex) =>
      prevIndex + 1 >= liveTurniere.length ? 0 : prevIndex + 1
    );
  };

  const decrementTurnierIndex = () => {
    setCurrentTurnierIndex((prevIndex) =>
      prevIndex - 1 < 0 ? liveTurniere.length - 1 : prevIndex - 1
    );
  };

  const handleListItemClick = (data) => {
    setSelectedEntry(data);
    setIsModalOpen(true);
  };
  useEffect(() => {
    if (selectedEntry) {
      setSpielerName(selectedEntry.spielerName);
      setRunde(selectedEntry.runde);
      setTisch(selectedEntry.tisch);
      setPunkte(selectedEntry.punkte);
    }
  }, [selectedEntry]);

  const saveUpdatedData = async () => {
    if (selectedEntry) {
      const entryRef = query(
        playerInfoCollection,
        where("tisch", "==", selectedEntry.tisch),
        where("runde", "==", selectedEntry.runde),
        where("spielerName", "==", selectedEntry.spielerName)
      );
      const querySnapshot = await getDocs(entryRef);
      const docToUpdate = querySnapshot.docs[0];

      await updateDoc(docToUpdate.ref, {
        spielerName: spielerName,
        runde: runde,
        tisch: tisch,
        punkte: punkte,
      });

      // Aktualisieren Sie den Eintrag in eintraege Zustand
      const updatedEintraege = eintraege.map((entry) => {
        if (entry.id === selectedEntry.id) {
          return { ...entry, spielerName, runde, tisch, punkte };
        }
        return entry;
      });
      setEintraege(updatedEintraege);

      // Schließen Sie das Modal
      setIsModalOpen(false);
    }
  };

  return (
    <Box className="rightContent">
      <Box
        sx={{
          "@media screen and (min-width: 1200px)": {
            display: "none",
            opacity: 1,
          },
        }}
      >
        <TopbarTwo />
      </Box>
      <SubHeaderTurnierErgebnisse
        currentRound={currentRound === 0 ? "Alle" : currentRound}
        incrementRound={incrementRound}
        decrementRound={decrementRound}
        currentTable={currentTable === 0 ? "Alle" : currentTable}
        incrementTable={incrementTable}
        decrementTable={decrementTable}
        onRoundTableChange={onRoundTableChange}
        currentTournament={liveTurniere[currentTurnierIndex]}
        decrementTurnierIndex={decrementTurnierIndex}
        incrementTurnierIndex={incrementTurnierIndex}
        liveTurniere={liveTurniere}
      />
      <Box sx={{ display: "flex" }}>
        <Box sx={{ flexGrow: "2" }}>
          {ergebnisse.length === 0 ? (
            <Box className="NoListItemTur">
              <Typography
                variant="h3"
                sx={{ margin: "2vh", textAlign: "center" }}
              >
                Starte ein Turnier auf deinem Dashboard <br></br> und trage die
                ersten Ergebnisse ein, um sie hier zu sehen
              </Typography>
              <Link
                className="LinkTagAdd"
                to="/ergebnis"
                style={{ textDecoration: "none", width: "30%" }}
                color="primary"
                aria-label="add"
                variant="extended"
              >
                <AddIcon />
                Trage das erste Ergebniss ein.
              </Link>
            </Box>
          ) : (
            <Box sx={{ marginTop: 2 }} className="ListTurnierInfo">
              <List className="ListTurnierInfoTitel">
                <ListItem>
                  <h3>SpielerName</h3>
                </ListItem>
                <ListItem
                  sx={{
                    "@media (max-width: 760px)": {
                      display: "none",
                      opacity: 1,
                    },
                  }}
                >
                  <h3>RundenNr.</h3>
                </ListItem>
                <ListItem
                  sx={{
                    "@media (max-width: 760px)": {
                      display: "none",
                      opacity: 1,
                    },
                  }}
                >
                  <h3>TischeNr.</h3>
                </ListItem>
                <ListItem sx={{ justifyContent: "flex-end", mr: "2vw" }}>
                  <h3>Punkte</h3>
                </ListItem>
              </List>
              <Box className="ListTurnierInfoContentALL">
                {eintraege.map((data) => {
                  const spielerData = spielerNamen.find(
                    (player) => player.spielername === data.spielerId
                  );
                  return (
                    <List className="ListTurnierInfoContent" key={data.id}>
                      <ListItem
                        key={data.id}
                        onClick={() => handleListItemClick(data)}
                      >
                        {data.spielerName}
                      </ListItem>
                      <ListItem
                        sx={{
                          "@media (max-width: 760px)": {
                            display: "none",
                            opacity: 1,
                          },
                        }}
                        key={`${data.id}-runde`}
                        onClick={() => handleListItemClick(data)}
                      >
                        {data.runde}
                      </ListItem>
                      <ListItem
                        sx={{
                          "@media (max-width: 760px)": {
                            display: "none",
                            opacity: 1,
                          },
                        }}
                        key={`${data.id}-tisch`}
                        onClick={() => handleListItemClick(data)}
                      >
                        {data.tisch}
                      </ListItem>
                      <ListItem
                        sx={{ justifyContent: "flex-end", mr: "2vw" }}
                        key={`${data.id}-punkte`}
                        onClick={() => handleListItemClick(data)}
                      >
                        {data.punkte}
                      </ListItem>
                    </List>
                  );
                })}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Modal
        className="ModalRegister"
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <Box className="modal-containerTwo" sx={{ p: 2 }}>
          <Box className="modal-containerTwoHeader">
            <Typography variant="h3" sx={{ mb: 2 }}>
              Eintrag aktualisieren
            </Typography>
            <Button
              onClick={() => setIsModalOpen(false)}
              className="LoginBtnThree"
              variant="icon"
            >
              X
            </Button>
          </Box>
          <TextField
            value={spielerName}
            onChange={(e) => setSpielerName(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            value={runde}
            onChange={(e) => setRunde(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            value={tisch}
            onChange={(e) => setTisch(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            value={punkte}
            onChange={(e) => setPunkte(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              className="LoginBtnFour"
              variant="contained"
              color="primary"
              onClick={saveUpdatedData}
            >
              Speichern
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
});

export default ErgebnissPage;
