import React, { useContext, useState } from "react";
import { Button } from "@mui/material";
import Cookies from "universal-cookie/cjs/Cookies";
import { UserContext } from "../App";
import { auth, db } from "../firebaseConfig";
import { doc, deleteDoc } from "firebase/firestore";
import { getAuth, deleteUser } from "firebase/auth";

/* <TODO>  
Der Knopf soll noch im ProfilHub hinzugefügt werden, damit der User sein Account löschen kann. 

</TODO> */

function DeleteUser() {
  const { loggedIn, updateLoggedIn, userAuthToken, updateUserAuthToken } =
    useContext(UserContext);
  const cookies = new Cookies();
  const [password, setPassword] = useState("");

  const handleDeleteUser = async () => {
    if (auth.currentUser) {
      const input = prompt("Bitte geben Sie Ihr Passwort ein:");
      if (input === null) {
        return;
      }
      setPassword(input);
      try {
        const uid = auth.currentUser.uid;
        const userRef = doc(db, "users", uid);
        await deleteDoc(userRef);
        await deleteUser(getAuth(), auth.currentUser);
        updateLoggedIn(false);
        updateUserAuthToken("");
        cookies.remove("authToken");
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleDeleteUser}
      disabled={!loggedIn}
      sx={{ position: "relative", bottom: "0" }}
    >
      Konto Löschen
    </Button>
  );
}

export default DeleteUser;
