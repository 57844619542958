import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  Grid,
  FormControl,
} from "@mui/material";

import {
  TurnierInfoCollection,
  UsersInfoCollection,
} from "../../firebaseConfig";
import { addDoc, query, onSnapshot } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import TopbarTwo from "../global/TopBarTwo";

const NewTurnierForm = () => {
  // NewTurnierForm is a component
  const [turnierName, setTurnierName] = useState(""); // turnierName is a state variable
  const [modus, setModus] = useState("");
  const [ort, setOrt] = useState("");
  const [admin, setAdmin] = useState("");
  const [spielerNamen, setSpielerNamen] = useState([]);
  const [userNames, setUserNames] = useState([]);
  const [activeRound, setActiveRound] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const q = query(UsersInfoCollection);
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const names = snapshot.docs.map((doc) => doc.data().spielerName);
      setUserNames(names);
    });
    return () => unsubscribe();
  }, []);

  const datalistItems = userNames.map((userName, index) => (
    <option key={index} value={userName} />
  ));

  //create a new turnierwith the data from the form
  const createNewTurnier = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!turnierName || !modus || !ort || !admin || !spielerNamen) {
      alert("Ups, du hast vergessen, das Formular komplett auszufüllen!");
      return;
    }

    try {
      await addDoc(TurnierInfoCollection, {
        name: turnierName,
        mode: modus,
        ort: ort,
        admin: admin,
        live: false,
        player: spielerNamen,
        activeRound: activeRound,
        userId: user.uid, // Add the user's uid
      });
      setTurnierName("");
      setModus("");
      setOrt("");
      setAdmin("");
      navigate("/");
    } catch (error) {
      alert("Ups, Es gibt gerade einen Fehler beim Erstellen deines Turniers:");
    }
  };

  const handleModusChange = (event) => {
    setModus(event.target.value);
    // Zurücksetzen der Spielerliste, falls bereits erstellt
    setSpielerNamen([]);
  };

  const handleSpielerNameChange = (event, index) => {
    // Kopie des aktuellen Spielerlisten-Arrays erstellen
    const newSpielerNamen = [...spielerNamen];
    // Spielername aktualisieren
    newSpielerNamen[index] = event.target.value;
    // Aktualisiertes Spielerlisten-Array setzen
    setSpielerNamen(newSpielerNamen);
  };

  // Anzahl der Inputfields basierend auf dem gewählten Modus
  let spielerInputFields = [];
  switch (modus) {
    case "Option 1":
      for (let i = 0; i < 12; i++) {
        spielerInputFields.push(
          <Grid item xs={6} key={i}>
            <TextField
              fullWidth
              label={`Spieler ${i + 1}`}
              value={spielerNamen[i] || ""} // || "" ist wichtig, da sonst undefined angezeigt wird
              onChange={(event) => handleSpielerNameChange(event, i)} // event.target.value
              inputProps={{
                list: "userNamesList",
                autoComplete: "off",
              }}
            />
          </Grid>
        );
      }
      break;
    case "Option 2":
      for (let i = 0; i < 16; i++) {
        spielerInputFields.push(
          <Grid item xs={6} key={i}>
            <TextField
              fullWidth
              label={`Spieler ${i + 1}`}
              value={spielerNamen[i] || ""}
              onChange={(event) => handleSpielerNameChange(event, i)}
              inputProps={{
                list: "userNamesList",
                autoComplete: "off",
              }}
            />
          </Grid>
        );
      }
      break;
    case "Option 3":
      for (let i = 0; i < 18; i++) {
        spielerInputFields.push(
          <Grid item xs={6} key={i}>
            <TextField
              fullWidth
              label={`Spieler ${i + 1}`}
              value={spielerNamen[i] || ""}
              onChange={(event) => handleSpielerNameChange(event, i)}
              inputProps={{
                list: "userNamesList",
                autoComplete: "off",
              }}
            />
          </Grid>
        );
      }
      break;
    case "Option 4":
      for (let i = 0; i < 20; i++) {
        spielerInputFields.push(
          <Grid item xs={6} key={i}>
            <TextField
              fullWidth
              label={`Spieler ${i + 1}`}
              value={spielerNamen[i] || ""}
              onChange={(event) => handleSpielerNameChange(event, i)}
              inputProps={{
                list: "userNamesList",
                autoComplete: "off",
              }}
            />
          </Grid>
        );
      }
      break;
    case "Option 5":
      for (let i = 0; i < 24; i++) {
        spielerInputFields.push(
          <Grid item xs={6} key={i}>
            <TextField
              fullWidth
              label={`Spieler ${i + 1}`}
              value={spielerNamen[i] || ""}
              onChange={(event) => handleSpielerNameChange(event, i)}
              inputProps={{
                list: "userNamesList",
                autoComplete: "off",
              }}
            />
          </Grid>
        );
      }
      break;
    default:
      break;
  }

  return (
    <Box className="addForm">
      <TopbarTwo />
      <Grid
        sx={{ width: "85vw", paddingLeft: "3vw", paddingRight: "3vw" }}
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <Box mb={3}>
            <Typography variant="h3">Neues Turnier erstellen</Typography>
          </Box>
          <Box mb={3}>
            <label className="TextFieldInputLabel" htmlFor="turniername">
              Turniername -- Verwende einen Namen und/oder eine Nummer zur
              eindeutigen Identifikation --
            </label>
            <TextField
              className="TextFieldInputUser"
              name="name"
              id="turniername"
              fullWidth
              value={turnierName}
              onChange={(event) => setTurnierName(event.target.value)}
              aria-label="Turniername"
            />
          </Box>
          <Box mb={3}>
            <FormControl fullWidth>
              <label className="TextFieldInputLabel" htmlFor="turniername">
                Modus
              </label>
              <Select
                name="modus"
                className="SelectInputUser"
                labelId="modus-select-label"
                placeholder="Modus"
                id="modus-select"
                value={modus}
                onChange={handleModusChange}
              >
                <MenuItem value="12 Spieler">12 Spieler</MenuItem>
                <MenuItem value="16 Spieler">16 Spieler</MenuItem>
                <MenuItem value="18 Spieler">18 Spieler</MenuItem>
                <MenuItem value="20 Spieler">20 Spieler</MenuItem>
                <MenuItem value="24 Spieler">24 Spieler</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box mb={3}>
            <label className="TextFieldInputLabel" htmlFor="turniername">
              Wo findet das Turnier statt?
            </label>
            <TextField
              name="ort"
              fullWidth
              value={ort}
              onChange={(event) => setOrt(event.target.value)}
              required={true}
            />
          </Box>
          <Box mb={3}>
            <label className="TextFieldInputLabel" htmlFor="turniername">
              Wer organisiert das Turnier?
            </label>
            <TextField
              name="admin"
              fullWidth
              value={admin}
              onChange={(event) => setAdmin(event.target.value)}
              required={true}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              width: "100%",
            }}
          ></Box>
        </Grid>

        <Grid item xs={12}>
          <Box mb={3}>
            <Typography variant="h3">Spieler hinzufügen</Typography>
          </Box>
          <Grid container spacing={2} className="eXTRAgROSS">
            <datalist id="userNamesList">{datalistItems}</datalist>
            {modus === "12 Spieler" &&
              [...Array(12)].map((_, index) => (
                <Grid item xs={6} key={index}>
                  <label className="TextFieldInputLabel" htmlFor="turniername">
                    {`Spieler ${index + 1}`}
                  </label>
                  <TextField
                    fullWidth
                    value={spielerNamen[index] || ""}
                    required={true}
                    onChange={(event) => handleSpielerNameChange(event, index)}
                    inputProps={{
                      list: "userNamesList",
                      autoComplete: "off",
                    }}
                  />
                </Grid>
              ))}
            {modus === "16 Spieler" &&
              [...Array(16)].map((_, index) => (
                <Grid item xs={6} key={index}>
                  <label className="TextFieldInputLabel" htmlFor="turniername">
                    {`Spieler ${index + 1}`}
                  </label>
                  <TextField
                    fullWidth
                    required={true}
                    value={spielerNamen[index] || ""}
                    onChange={(event) => handleSpielerNameChange(event, index)}
                    inputProps={{
                      list: "userNamesList",
                      autoComplete: "off",
                    }}
                  />
                </Grid>
              ))}
            {modus === "18 Spieler" &&
              [...Array(18)].map((_, index) => (
                <Grid item xs={6} key={index}>
                  <label className="TextFieldInputLabel" htmlFor="turniername">
                    {`Spieler ${index + 1}`}
                  </label>
                  <TextField
                    fullWidth
                    required={true}
                    value={spielerNamen[index] || ""}
                    onChange={(event) => handleSpielerNameChange(event, index)}
                    inputProps={{
                      list: "userNamesList",
                      autoComplete: "off",
                    }}
                  />
                </Grid>
              ))}

            {modus === "20 Spieler" &&
              [...Array(20)].map((_, index) => (
                <Grid item xs={6} key={index}>
                  <label className="TextFieldInputLabel" htmlFor="turniername">
                    {`Spieler ${index + 1}`}
                  </label>
                  <TextField
                    fullWidth
                    required={true}
                    value={spielerNamen[index] || ""}
                    onChange={(event) => handleSpielerNameChange(event, index)}
                    inputProps={{
                      list: "userNamesList",
                      autoComplete: "off",
                    }}
                  />
                </Grid>
              ))}
            {modus === "24 Spieler" &&
              [...Array(24)].map((_, index) => (
                <Grid item xs={6} key={index}>
                  <label className="TextFieldInputLabel" htmlFor="turniername">
                    {`Spieler ${index + 1}`}
                  </label>
                  <TextField
                    fullWidth
                    required={true}
                    value={spielerNamen[index] || ""}
                    onChange={(event) => handleSpielerNameChange(event, index)}
                    inputProps={{
                      list: "userNamesList",
                      autoComplete: "off",
                    }}
                  />
                </Grid>
              ))}
          </Grid>
          <FormControl className="addForm">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={createNewTurnier}
              sx={{ mt: 3, mb: 2 }}
            >
              Turnier erstellen
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};
export default NewTurnierForm;
