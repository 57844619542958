import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  Modal,
  Backdrop,
  Fade,
} from "@mui/material";

import { getUsersInfo } from "../../firebaseConfig"; // Importieren Sie getUsersInfo aus Ihrer Firebase-Konfigurationsdatei

import { getAuth } from "firebase/auth";

import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import TopbarTwo from "../../scene/global/TopBarTwo";
import SubHeaderDashboard from "../../components/SubHeaderDashboard";

function ListGlobalPlayer() {
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // Add a new state variable for the search term
  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
  }, []);
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    const fetchPlayers = async () => {
      const usersData = await getUsersInfo();
      console.log(usersData); // Debug-Ausgabes
      setPlayers(usersData);
    };
    fetchPlayers();
  }, []);

  const [modalOpen, setModalOpen] = useState(false);
  const [newPlayer, setNewPlayer] = useState({ name: "" });
  const [filter, setFilter] = useState("");

  const handleAddPlayer = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setNewPlayer({ name: "" });
  };

  const handleNewPlayerChange = (event) => {
    setNewPlayer({
      ...newPlayer,
      [event.target.name]: event.target.value,
    });
  };

  const handleNewPlayerSubmit = () => {
    setPlayers([...players, newPlayer]);
    setModalOpen(false);
    setNewPlayer({ name: "" });
  };

  const handleDeletePlayer = (index) => {
    setPlayers(players.filter((player, i) => i !== index));
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredPlayers = players.filter(
    (player) =>
      player.publicProfile === true &&
      player.name.toLowerCase().includes(searchTerm) // Add this condition to filter based on the search term
  );

  const handleProfileClick = (profile) => {
    setSelectedProfile(profile);
  };

  return (
    /* Titel Header*/
    <Box sx={{ padding: 2 }} className="rightContent">
      <Box
        sx={{
          "@media screen and (min-width: 1200px)": {
            display: "none",
            opacity: 1,
          },
        }}
      >
        <TopbarTwo />
      </Box>
      <SubHeaderDashboard />
      <Box sx={{ display: "flex" }}>
        <Box sx={{ padding: 2, flexGrow: "2" }}>
          <Box
            sx={{
              width: "100",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h2">Spielerliste</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: 1,
              marginBottom: 1,
            }}
          >
            <div className="search-box">
              <form
                className="search-box"
                action="/search"
                method="get"
                onSubmit={(e) => e.preventDefault()}
              >
                <input
                  type="text"
                  name="SearchBar"
                  placeholder="Suche..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </form>
            </div>
          </Box>
          <Box className="ListTurnierInfoContentALL">
            {/* Tabelle mit allen Spielern */}
            <List sx={{ marginTop: 4 }}>
              {filteredPlayers.map((player, index) => (
                <ListItem
                  key={index}
                  id="ListItemPlayerGlobal"
                  onClick={() => handleProfileClick(player)}
                >
                  <img
                    src={player.image}
                    alt="NoPic"
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "cover",
                      borderRadius: "50%",
                      marginRight: "10px",
                    }}
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{ flexGrow: 1 }}
                    id="ListItemTurnierGlobalItem"
                  >
                    {player.name}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Box>
          <Modal
            open={selectedProfile !== null}
            onClose={() => setSelectedProfile(null)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={selectedProfile !== null}>
              <Box className="modal-box">
                {selectedProfile && (
                  <>
                    <Box className="modal-profile">
                      <img
                        src={selectedProfile.image || "default-image-url"}
                        alt="ProfilBild"
                        className="profile-image"
                      />
                      <Box className="modal-details">
                        <Typography variant="h2" className="modal-name">
                          {selectedProfile.name}
                        </Typography>
                        <Box className="modal-contact">
                          <Typography variant="subtitle1">
                            <a
                              href={selectedProfile.instagramLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <InstagramIcon sx={{ color: "#141414" }} />
                            </a>
                          </Typography>
                          <Typography variant="subtitle1">
                            <a
                              href={selectedProfile.facebookLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FacebookIcon sx={{ color: "#141414" }} />
                            </a>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Typography variant="h5" className="modal-bio" mt={2}>
                      {" "}
                      {selectedProfile.bio
                        ? selectedProfile.bio
                        : "mmh, da ist wohl nichts drin..."}
                    </Typography>
                  </>
                )}
                <Box className="modal-close">
                  <Button onClick={() => setSelectedProfile(null)}>
                    Schließen
                  </Button>
                </Box>
              </Box>
            </Fade>
          </Modal>
        </Box>
      </Box>
    </Box>
  );
}

export default ListGlobalPlayer;
