import React, { useContext } from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Cookies from "universal-cookie/cjs/Cookies";
import { UserContext } from "../App";

function LogoutButton({ collapsed }) {
  const { loggedIn, updateLoggedIn, userAuthToken, updateUserAuthToken } =
    useContext(UserContext);
  const cookies = new Cookies();

  const handleLogout = () => {
    updateLoggedIn(false);
    updateUserAuthToken("");
    cookies.remove("authToken");
  };

  return (
    <ListItem
      className="SidebarItemText"
      button
      onClick={handleLogout}
      disabled={!loggedIn}
    >
      <ListItemIcon
        sx={{
          "@media screen and (max-width: 760px)": {
            display: "none",
          },
          "@media screen and (max-height: 480px)": {
            display: "none",
          },
        }}
      >
        <ExitToAppIcon />
      </ListItemIcon>
      <ListItemText
        primary="Logout"
        sx={{
          display: collapsed ? "none" : "block",
          "@media screen and (max-width: 760px)": {
            display: "block",
          },
          "@media screen and (max-height: 480px)": {
            display: "block",
          },
        }}
      />
    </ListItem>
  );
}

export default LogoutButton;
