import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);
ChartJS.register(ChartDataLabels);

const PlayerBarChart = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(props.tableData);
  }, [props.tableData]);

  const backgroundColor = [
    "rgba(139, 40, 219, 0.8)", // #8b28db
    "rgba(255, 171, 11, 0.8)", // #ffab0b
    "rgba(170, 55, 176, 0.8)", // #323232
    "rgba(112, 224, 205, 0.7)", //  #463246
  ];

  const chartData = {
    labels: data.map((item) => item.spielerName),

    datasets: [
      {
        data: data.map((item) => item.punkte),
        backgroundColor: data.map((item, index) =>
          item.punkte >= 50 ? "rgba(255, 0, 0, 0.8)" : backgroundColor[index % backgroundColor.length]
        ),
        borderColor: data.map((item, index) =>
          item.punkte >= 50 ? "rgba(255, 0, 0, 0.8)" : backgroundColor[index % backgroundColor.length]
        ),
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    indexAxis: "y",
    scales: {
      y: {
        beginAtZero: true,
        min: 0, // Setzt den minimalen Wert der y-Achse
        max: 100, // Setzt den maximalen Wert der y-Achse
        ticks: {
          color: "#fcfcfc", // Setzt die Farbe der y-Achsen-Beschriftungen
  
          font: {
            size: 22, // Setzt die Schriftgröße der y-Achsen-Beschriftungen
            anchor: "center",
            align: "center",
          },
        },
      },
      x: {
        ticks: {
          display: false, // Setzt die Anzeige der X-Achsen-Beschriftungen auf "false"
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },

      datalabels: {
        font: {
          size: "28px",
        },
        color: "#fce6ff",

        anchor: "center",
        align: "end",
      },
      annotation: {
        annotations: [
          {
            type: "line",
            mode: "horizontal",
            scaleID: "y",
            value: 75,
            borderColor: "#fcfcfc",
            borderWidth: 2,
            label: {
              enabled: false,
            },
          },
        ],
      },
    },
  };

  return (
    <div>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default PlayerBarChart;
