import React, { useState, useEffect } from "react";
import "firebase/compat/database";
import { Box, Typography, Grid, Button } from "@mui/material";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { Link } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { getDocs, query, where, doc, updateDoc } from "firebase/firestore";

import { db, TurnierInfoCollection } from "../firebaseConfig";

const SubHeaderTurnier = ({
  currentRound,
  incrementRound,
  decrementRound,
  currentTable,
  incrementTable,
  decrementTable,
  onGetDataButtonClick,
  currentTournament,
  decrementTurnierIndex,
  incrementTurnierIndex,
  liveTurniere,
}) => {
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(10);
  const [isRunning, setIsRunning] = useState();
  const [progressColor, setProgressColor] = useState("primary");
  const [currentTournamentNameState, setCurrentTournamentNameState] =
    useState("");

  const updateTurnierLiveStatus = async (live) => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      const uid = user.uid;
      const q = query(
        TurnierInfoCollection,
        where("live", "==", true),
        where("userId", "==", uid)
      );
      const turnierDoc = await getDocs(q);
      if (turnierDoc.size > 0) {
        const turnierRef = doc(db, "TurnierInfo", turnierDoc.docs[0].id);
        await updateDoc(turnierRef, { live });
      }
    }
  };

  useEffect(() => {
    if (currentTournament?.name != undefined) {
      setCurrentTournamentNameState(currentTournament?.name);
    }
  }, [currentTournament]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (isRunning) {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 0 : prevProgress + 1
        );
        setBuffer((prevBuffer) => (prevBuffer >= 100 ? 0 : prevBuffer + 1));
      }
    }, 100);
    return () => {
      clearInterval(timer);
    };
  }, [isRunning]);

  // Funktion zum Wechseln der ProgressBar-Farbe und zum Anhalten der Animation
  const handleProgressBarClick = async () => {
    setIsRunning(!isRunning);
    if (isRunning) {
      await updateTurnierLiveStatus(false);
    }
  };

  return (
    <Grid className="SubHeaderTurnier" container spacing={2} mt={1}>
      <Grid item xs={12} sm={4} className="txtBox">
        <Typography variant="h2">LIVE Ergebnisse</Typography>
      </Grid>
      <Grid item xs={12} sm={4} className="arrowBox">
        <Box className="arrowBoxContent">
          <Button onClick={decrementTurnierIndex}>
            <ArrowBackIosIcon style={{ color: "white" }} />
          </Button>
          <Typography variant="h5" sx={{ marginX: "1rem" }}>
            {currentTournamentNameState}
          </Typography>
          <Button onClick={incrementTurnierIndex}>
            <ArrowForwardIosIcon style={{ color: "white" }} />
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} className="btnBox">
        <Box>
          <Link to="/ergebnis" className="LinkSide">
            <Button
              id="addResultBtn"
              variant="contained"
              color="primary"
              size="large"
              sx={{ textDecorationLine: "none" }}
            >
              Ergebnis hinzufügen
            </Button>
          </Link>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} className="arrowBox">
        <Box className="arrowBoxContent">
          <Button onClick={decrementRound}>
            <ArrowBackIosIcon style={{ color: "white" }} />
          </Button>
          <Typography variant="h5" sx={{ marginX: "1rem" }}>
            Runde {currentRound === -1 ? "Alle" : currentRound}
          </Typography>
          <Button onClick={incrementRound}>
            <ArrowForwardIosIcon style={{ color: "white" }} />
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} className="arrowBox">
        <Box className="arrowBoxContent">
          {" "}
          <Button onClick={decrementTable}>
            <ArrowBackIosIcon style={{ color: "white" }} />
          </Button>
          <Typography variant="h5" sx={{ marginX: "1rem" }}>
            Tisch {currentTable === -1 ? "Alle" : currentTable}
          </Typography>
          <Button onClick={incrementTable}>
            <ArrowForwardIosIcon style={{ color: "white" }} />
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} className="btnBox">
        <Box>
          <Button
            id="addResultBtn"
            className="LoginBtn"
            fullWidth
            size="large"
            color="primary"
            variant="contained"
            onClick={() => {
              onGetDataButtonClick();
            }}
          >
            Daten aktualisieren
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SubHeaderTurnier;
