import { addEventListener } from "react";
import { initializeApp } from "firebase/app";

import {
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "@firebase/auth";
import {
  getFirestore,
  getDocs,
  getDoc,
  collection,
  doc,
  addDoc,
  query,
  where,
  orderBy,
  limit,
  updateDoc,
  deleteDoc,
  onSnapshot,
} from "@firebase/firestore";

import {
  storage,
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  uploadBytes,
} from "firebase/storage";
import { TroubleshootTwoTone } from "@mui/icons-material";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_apiKey,
  authDomain: process.env.REACT_APP_FB_authDomain,
  projectId: process.env.REACT_APP_FB_projectId,
  storageBucket: process.env.REACT_APP_FB_storageBucket,
  messagingSenderId: process.env.REACT_APP_FB_messagingSenderId,
  appId: process.env.REACT_APP_FB_appId,
  measurementId: process.env.REACT_APP_FB_measurementId,
};
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const gp = new GoogleAuthProvider();
export const speicher = getStorage();
export const db = getFirestore();

//Collection für TurnierInfo - Hier werden alle Global Daten zu den Turnieren gespeichert
export const TurnierInfoCollection = collection(db, "TurnierInfo");

//SubCollection für TurnierInfo - Hier werden alle Global Daten zu den Turnieren gespeichert
export const TurnierInfoSubCollection = doc(
  db,
  "TurnierInfo",
  "MsSJ4Fd0xtNNWAVnrtS9",
  "ergebnisse",
  "z9sj0AyZgdrJ4CTX4Vc8"
);

//Collection für ModusInfo - Hier wird gespeichert, Modusgespielt wird /anzahl Spieler/modeActive/name
export const modusInfoCollection = collection(db, "modus");
//Collection für PlayerInfov - welche Mitspieler wie viel Punkte haben pro Turnier.
export const playerInfoCollection = collection(db, "player");
//Collection für TischNrInfo - Anzahl möglicher Tische pro Turnier
export const tischNrInfoCollection = collection(db, "TischNr");

//Collection für UserNrInfo - gemeldete Admins User - Test
export const UsersInfoCollection = collection(db, "users");

//TurnierInfo

export const getTurnierInfo = async () => {
  const turnierInfoRef = collection(db, "TurnierInfo");
  const snapshot = await getDocs(turnierInfoRef);
  snapshot.docs.forEach((doc) => {
    const data = doc.data();
  });
};

//Users
export const getUsersInfo = async () => {
  const usersInfoRef = collection(db, "users");
  const snapshot = await getDocs(usersInfoRef);
  const usersData = [];
  snapshot.docs.forEach((doc) => {
    const data = doc.data();
    usersData.push({
      name: data.spielerName,
      email: data.email,
      image: data.image,
      instagramLink: data.instagramLink,
      facebookLink: data.facebookLink,
      bio: data.bio,
      publicProfile: data.publicProfile,
    });
  });
  return usersData;
};

//Player
export const getPlayerInfo = async () => {
  const playerInfoRef = collection(db, "player");
  const snapshot = await getDocs(playerInfoRef);
  snapshot.docs.forEach((doc) => {
    const data = doc.data();
  });
};

(async () => {
  await getTurnierInfo();
  await getUsersInfo();
  await getPlayerInfo();
})();

// Get player documents that match the given tournament name
export const getPlayerInfoByTurnierAndName = async (
  turnierName,
  spielerName
) => {
  const playerInfoRef = collection(db, "player");
  const q = query(
    playerInfoRef,
    where("turnier", "==", turnierName),
    where("spielerName", "==", spielerName)
  );
  const snapshot = await getDocs(q);
  const playersData = [];
  snapshot.docs.forEach((doc) => {
    const data = doc.data();

    playersData.push(data);
  });
  return playersData;
};

// Storage

export const uplaod = async (file, userId) => {
  if (!file) return;

  const storage = getStorage();
  const filePath = `profilpic/${userId}_${file.name}`;
  const fileRef = ref(storage, filePath);

  try {
    await uploadBytes(fileRef, file);
    const downloadURL = await getDownloadURL(fileRef);

    return downloadURL;
  } catch (error) {
    return null;
  }
};

export const turnierInfoCollectionName = "TurnierInfo";
export const playerInfoCollectionName = "player";
