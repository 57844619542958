import { useState } from "react";

/* <TODO>  
Komplett Vergessen für was das ist und warum ich es lieber nicht löschen solltem muss prüfen 
was hier genau gemacht wird.


</TODO> */

function useNewTurnierSubmit() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newTurnierName, setNewTurnierName] = useState("");
  const [newTurnierModus, setNewTurnierModus] = useState("");
  const [newTurnierLocation, setNewTurnierLocation] = useState("");
  const [newTurnierScore, setNewTurnierScore] = useState("");

  const handleNewTurnierSubmit = () => {
    // add your logic to save the new turnier data to the database here

    setNewTurnierName("");
    setNewTurnierModus("");
    setNewTurnierLocation("");
    setNewTurnierScore("");
    setDialogOpen(false);
  };

  return {
    dialogOpen,
    setDialogOpen,
    newTurnierName,
    setNewTurnierName,
    newTurnierModus,
    setNewTurnierModus,
    newTurnierLocation,
    setNewTurnierLocation,
    newTurnierScore,
    setNewTurnierScore,
    handleNewTurnierSubmit,
  };
}

export default useNewTurnierSubmit;
