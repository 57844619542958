import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  useTheme,
  IconButton,
  TextField,
} from "@mui/material";
import { Bar } from "@nivo/bar";

import "../../index.css";

import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";

import TopbarTwo from "../../scene/global/TopBarTwo";

import { TurnierInfoCollection } from "../../firebaseConfig";
import SubHeaderDashboard from "../../components/SubHeaderDashboard";
import useNewTurnierSubmit from "../../components/JS/TurnierSubmit";
import { tokens } from "../../theme";
import { getDocs } from "@firebase/firestore";

import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

const AllTurnier = ({}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTurnier, setSelectedTurnier] = useState(null);

  const { handleNewTurnierSubmit } = useNewTurnierSubmit();

  const theme = useTheme();

  const [turnierInfo, setTurnierInfo] = useState([]); // turnierInfo is a state variable

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const getTurnierInfo = async () => {
      const data = await getDocs(TurnierInfoCollection);
      setTurnierInfo(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getTurnierInfo();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredTurniere = turnierInfo.filter((turnier) => {
    const name = turnier.name?.toLowerCase() || "";
    const mode = turnier.mode?.toLowerCase() || "";
    const ort = turnier.ort?.toLowerCase() || "";
    const admin = turnier.admin?.toLowerCase() || "";

    const searchTermLower = searchTerm.toLowerCase();

    return (
      name.includes(searchTermLower) ||
      mode.includes(searchTermLower) ||
      ort.includes(searchTermLower) ||
      admin.includes(searchTermLower)
    );
  });

  //Modal für Turnier erstellen
  const handleTurnierClick = (turnier) => {
    setSelectedTurnier(turnier);
    setModalOpen(true);
  };

  return (
    <Box className="rightContent">
      <Box sx={{ display: "flex" }}>
        <Box key="subheader-dashboard" sx={{ padding: 2, flexGrow: "2" }}>
          <Box
            sx={{
              "@media screen and (min-width: 1200px)": {
                display: "none",
                opacity: 1,
              },
            }}
          >
            <TopbarTwo />
          </Box>
          <SubHeaderDashboard />
          <Typography variant="h2" sx={{ marginTop: 3 }}>
            Alle Turniere
          </Typography>
          <div className="search-box">
            <form className="search-box" onSubmit={(e) => e.preventDefault()}>
              <input
                type="text"
                name="SearchBar"
                placeholder=" Suche alle Turniere"
                value={searchTerm}
                onChange={handleSearchChange}
              ></input>
            </form>
          </div>
          <Box sx={{ marginTop: 2 }} className="ListTurnierInfo">
            <List className="ListTurnierInfoTitel">
              <ListItem>
                <h3>Turniername</h3>
              </ListItem>
              <ListItem>
                <h3>Modus</h3>
              </ListItem>
              <ListItem
                sx={{
                  "@media screen and (max-width: 1200px)": {
                    display: "none",
                    opacity: 1,
                  },
                }}
              >
                <h3>Austragungsort</h3>
              </ListItem>
              <ListItem
                sx={{
                  "@media screen and (max-width: 1200px)": {
                    display: "none",
                    opacity: 1,
                  },
                }}
              >
                <h3>Organisator</h3>
              </ListItem>
              <ListItem
                sx={{
                  "@media screen and (max-width: 1200px)": {
                    display: "none",
                    opacity: 1,
                  },
                }}
              >
                <h3>Live/Beendet</h3>
              </ListItem>
            </List>
            <Box className="ListTurnierInfoContentALL">
              {filteredTurniere.map((data) => {
                return (
                  <List
                    className="ListTurnierInfoContentTwo"
                    key={data.id}
                    onClick={() => handleTurnierClick(data)}
                  >
                    <ListItem>{data.name}</ListItem>
                    <ListItem>{data.mode}</ListItem>
                    <ListItem
                      sx={{
                        "@media screen and (max-width: 1200px)": {
                          display: "none",
                          opacity: 1,
                        },
                      }}
                    >
                      {data.ort}
                    </ListItem>
                    <ListItem
                      sx={{
                        "@media screen and (max-width: 1200px)": {
                          display: "none",
                          opacity: 1,
                        },
                      }}
                    >
                      {data.admin}
                    </ListItem>
                    <ListItem
                      sx={{
                        "@media screen and (max-width: 1200px)": {
                          display: "none",
                          opacity: 1,
                        },
                      }}
                    >
                      {data.live ? (
                        <PlayCircleOutlineIcon
                          id="glowDotLive"
                          style={{ color: "green" }}
                        />
                      ) : (
                        <PauseCircleOutlineIcon
                          id="glowDotLive"
                          style={{ color: "red" }}
                        />
                      )}
                    </ListItem>
                  </List>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-turnier-info"
        aria-describedby="modal-turnier-info-description"
      >
        <Box
          sx={{
            color: "#141b2d",
            backgroundColor: "#e0e0e0",
            borderRadius: 4,
            boxShadow: 24,
            padding: 4,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "85vw",
            maxWidth: "600px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 2,
              width: "100%",
            }}
          >
            <Typography sx={{ width: "100%" }} variant="h3">
              Turnierinformationen
            </Typography>
            <IconButton onClick={() => setModalOpen(false)}>
              <CloseIcon sx={{ color: "#141414" }} />
            </IconButton>
          </Box>
          <Box className="ModalAllTurnier">
            <Box className="ModalAllTurnierTI">
              <Typography id="modal-turnier-info" variant="h6">
                <span className="spanTitel"> {selectedTurnier?.name}</span>
              </Typography>
              <Typography id="modal-turnier-info-description" sx={{ mt: 2 }}>
                <span className="spanTitelT">Modus:</span>{" "}
                {selectedTurnier?.mode}
                <br />
                <span className="spanTitelT">Ort:</span>
                {selectedTurnier?.ort}
                <br />
                <span className="spanTitelT">Organisation:</span>
                {selectedTurnier?.admin}
                <br />
                <span className="spanTitelT">Status:</span>
                {selectedTurnier?.live ? "Live" : "Beendet"}
              </Typography>
            </Box>
            <Box className="ModalAllTurnierSI">
              <Typography id="modal-turnier-info" variant="h6" component="h2">
                <span className="spanTitelT">Spieler:</span>
              </Typography>
              {selectedTurnier?.player?.map((player, index) => (
                <Typography sx={{ margin: "0.5" }} key={index}>
                  {player}
                </Typography>
              ))}
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
export default AllTurnier;
